var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createUnique } from '@common/application/utils/common';
import { Cookie } from '@common/application/utils/cookie';
import { STORAGE_PREFIX, USER_STORAGE_KEY } from '@common/constants/common';
import { cookieOptions } from '@common/constants/env';
import { parse } from 'cookie';
import { AES, enc } from 'crypto-ts';
import { get } from 'lodash-es';
export default function checkAndSetUserSession(ctx) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const cryptoKey = STORAGE_PREFIX;
        // 处理accessToken和userssion
        // let userSession = ctx ? Cookie.get(ctx, USER_STORAGE_KEY) : '';
        const { req } = ctx;
        const cookies = parse(((_a = req === null || req === void 0 ? void 0 : req.headers) === null || _a === void 0 ? void 0 : _a.cookie) || '');
        // 处理accessToken和userssion
        let userSession = ctx
            ? get(cookies, `${STORAGE_PREFIX}${USER_STORAGE_KEY}`)
                ? AES.decrypt(get(cookies, `${STORAGE_PREFIX}${USER_STORAGE_KEY}`), cryptoKey).toString(enc.Utf8)
                : ''
            : '';
        if (!userSession && ctx) {
            const id = createUnique();
            Cookie.set(ctx, USER_STORAGE_KEY, id, 365, cookieOptions);
            userSession = id;
        }
        return userSession;
    });
}
