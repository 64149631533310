import { classNames } from '@common/application/utils/classnames';
import { useToggle } from 'ahooks';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';

import { GoodsCategory } from '@/components/common/goods-category';
import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

export const Category = () => {
  const { t } = useTranslation(['common']);
  const [open, openActions] = useToggle();
  const timer = useRef<NodeJS.Timer | null>(null);

  const triggerEl = useRef<HTMLDivElement | null>(null);
  const [categoryTop, setCategoryTop] = useState<number>(0);

  const closeCategory = (callback?: () => void) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      openActions.set(false);
      if (callback) {
        callback();
      }
    }, 200);
  };

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <div
      className="h-full text-base w-full categoryHover"
      onMouseEnter={() => {
        if (timer.current) clearTimeout(timer.current);
        setCategoryTop(triggerEl.current?.getBoundingClientRect().bottom || 0);
        openActions.set(true);
      }}
      onMouseLeave={() => closeCategory()}
    >
      <div className="pad:hidden mobile:block h-full">
        <BlackLineIcon className="w-6 h-6" icon="category" />
      </div>
      <div
        ref={triggerEl}
        className={classNames(
          'pad:flex mobile:hidden w-full h-full flex just-start items-center text-black cursor-pointer'
        )}
      >
        <div className={classNames('flex items-center')}>
          <BlackLineIcon className="w-6 h-6" icon="category" />
          <div className={classNames('ml-1 mr-2 text-black-1 text-sm cursor-pointer font-medium')}>
            {t('notice.category')}
          </div>
          <BlackLineIcon className="w-4 h-4" icon={open ? 'top-arrow' : 'bottom-arrow'} />
        </div>
      </div>
      <GoodsCategory open={open} top={categoryTop} onHide={closeCategory} />
    </div>
  );
};
