import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const ColorLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  imageWithIcon: 20,
  icons: {
    'right-arrow-long': 1,
    car: 2,
    store: 3,
    x: 4,
    warning: 5,
    broadcast: 6,
    'cart-plus': 7,
    coupon: 8,
    selected: 9,
    'count-down': 10,
    'down-arrow': 11
  }
});
