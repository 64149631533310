var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import RcForm, { useForm as useRcFormInstance } from 'rc-field-form';
import React, { useMemo } from 'react';
import { FormContext } from './context';
export const Form = ((props) => {
    const { itemClassName, itemErrorClassName, labelClassName, labelErrorClassName, inputClassName, inputErrorClassName, colon, disabled, children, name, component = 'form', form } = props, rcFormProps = __rest(props, ["itemClassName", "itemErrorClassName", "labelClassName", "labelErrorClassName", "inputClassName", "inputErrorClassName", "colon", "disabled", "children", "name", "component", "form"]);
    const [rcFormInstance] = useRcFormInstance(form);
    const formContextValue = useMemo(() => ({
        isDefault: false,
        className: itemClassName,
        errorClassName: itemErrorClassName,
        labelClassName,
        labelErrorClassName,
        inputClassName,
        inputErrorClassName,
        colon,
        disabled,
        instance: rcFormInstance
    }), [
        itemClassName,
        itemErrorClassName,
        labelClassName,
        labelErrorClassName,
        inputClassName,
        inputErrorClassName,
        colon,
        disabled,
        rcFormInstance
    ]);
    return (_jsx(FormContext.Provider, Object.assign({ value: formContextValue }, { children: _jsx(RcForm, Object.assign({ id: name, name: name, component: component, form: rcFormInstance }, rcFormProps, { children: children })) })));
});
Form.displayName = 'Form';
Form.defaultProps = {
    component: 'form'
};
