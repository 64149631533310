import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const WhiteOutlineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    user: 1,
    close: 2
  }
});
