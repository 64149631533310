import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { RadioChecked } from '@common/components/icon/radio-checked';
import { RadioDisabled } from '@common/components/icon/radio-disabled';
import { RadioUnchecked } from '@common/components/icon/radio-unchecked';
import React, { useContext, useEffect } from 'react';
import { RadioGroupContext } from './context';
import { useMergedRadioProps } from './hooks';
export const Radio = (props) => {
    const { className, disabledClassName = '', checkedClassName = '', iconClassName, iconCheckedClassName = '', iconDisabledClassName = '', contentClassName, contentCheckedClassName = '', defaultChecked, checked, name, value, disabled, children, onChange } = useMergedRadioProps(props);
    const group = useContext(RadioGroupContext);
    useEffect(() => {
        if (!group.isDefault && value) {
            group.registerValue(value, defaultChecked);
            return () => group.destroyValue(value);
        }
    }, [group, value]);
    const IconComponent = disabled ? RadioDisabled : checked ? RadioChecked : RadioUnchecked;
    return (_jsxs("label", Object.assign({ className: classNames('relative inline-flex items-center', {
            'opacity-50 cursor-not-allowed': disabled,
            'active:opacity-80 cursor-pointer': !disabled,
            [checkedClassName]: checked,
            [disabledClassName]: disabled
        }, className) }, { children: [_jsx("input", { className: classNames('absolute z-[100] w-full h-full opacity-0', {
                    'cursor-not-allowed': disabled
                }), type: "radio", name: name, defaultChecked: defaultChecked, checked: checked, disabled: disabled, onChange: onChange }), _jsx(IconComponent, { className: classNames('w-4 h-4 rounded-full', iconClassName, {
                    [iconCheckedClassName]: checked,
                    [iconDisabledClassName]: disabled
                }) }), children && (_jsx("span", Object.assign({ className: classNames('ml-1', contentClassName, {
                    [contentCheckedClassName]: checked
                }) }, { children: children })))] })));
};
Radio.displayName = 'Radio';
Radio.defaultProps = {};
