import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useInRN } from '@common/application/hooks/use-in-rn';
import { useUpdateCartCount } from '@common/application/store/cart';
import { useSSO } from '@common/application/store/common';
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { useUpdateCartNumberListener } from '@common/application/utils/event';
import { ROUTE_MAP } from '@common/constants/route-map';
import { useRouter } from 'next/router';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

import { Cart } from './cart';
import { HeaderNotice } from './header-notice';
import { Logo } from './logo';
import { Navigation } from './navigation';
import { Notice } from './notice';
import { Search } from './search';
import { User } from './user';

export interface HeaderProps {
  destroyCategory?: boolean;
  showUser?: boolean;
  showNotice?: boolean;
  /** 是否展示移动端HEAD搜索区域 */
  showHeadSearch?: boolean;
  children?: React.ReactNode;
}
const SearchElement = () => {
  const { mobile } = useBreakpoint();
  return mobile ? (
    <div className="flex items-center justify-between">
      <Logo className="w-25" />
    </div>
  ) : (
    <Search />
  );
};
export const Header: React.FC<HeaderProps> = (props) => {
  const {
    destroyCategory,
    showUser = true,
    showNotice = true,
    showHeadSearch = true,
    children
  } = props;
  const { mobile } = useBreakpoint();
  const router = useRouter();
  const inRN = useInRN();
  const updateCartCount = useUpdateCartCount();
  useUpdateCartNumberListener(updateCartCount);
  const sso = useSSO();

  return (
    <>
      <HeaderNotice />
      {!inRN && (
        <header className="w-full bg-white relative z-5 pc:h-19.5">
          <div className="container mx-auto h-full">
            <div
              className={classNames(
                'relative flex items-center',
                'h-14 justify-between',
                'pc:grid pc:grid-cols-12 pc:h-full pc:justify-center'
              )}
            >
              <div className={classNames('hidden', 'pc:grid w-32 h-11.5')}>
                <Logo />
              </div>
              <div className={classNames('pc:col-start-4 pc:col-end-9')}>
                {showHeadSearch ? <SearchElement /> : children}
              </div>
              {showHeadSearch && (
                <div
                  className={classNames(
                    'flex items-center',
                    'gap-3',
                    'pc:grid grid-flow-col pc:col-span-4 pc:grid-flow-col pc:justify-self-end pc:gap-6'
                  )}
                >
                  {mobile && (
                    <BlackLineIcon
                      icon="search"
                      onClick={() => {
                        router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: '' }));
                      }}
                      className="w-6 h-6 cursor-pointer"
                    />
                  )}
                  {sso.settled && (
                    <>
                      {showNotice && <Notice dark />}
                      {showUser && <User dark />}
                      <Cart dark />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </header>
      )}
      {/* 分割线 */}
      <div
        className={classNames(
          'w-full bg-gradient-to-l from-[#DC3030] to-[#9B2242]',
          'h-0.5',
          'pc:h-1'
        )}
      ></div>
      {!destroyCategory && <Navigation />}
    </>
  );
};
