import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LocalStorage from '@common/application/utils/storage';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { ACCEPT_COOKIE_STORAGE_KEY } from '../../constants/common';
export const AcceptCookie = () => {
    const { t } = useTranslation();
    const [accept, setAccept] = useState(() => LocalStorage.get(ACCEPT_COOKIE_STORAGE_KEY));
    if (accept)
        return null;
    return (_jsx("div", Object.assign({ className: "fixed left-0 bottom-0 z-[10000] w-full bg-[rgba(0,0,0,0.7)]", "data-testid": "accept-cookie" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-center container pt-4 pb-5 pc:flex-row pc:justify-between pc:pb-4" }, { children: [_jsx("div", Object.assign({ className: "pb-4 text-white text-[15px] pc:pb-0 pc:text-base" }, { children: t('common:acceptCookie.msg') })), _jsx("button", Object.assign({ className: "w-32 h-10.5 text-base text-[#4780F3] font-medium rounded-sm bg-white pc:w-40.5 pc:h-12 pc:text-[17px]", onClick: () => {
                        LocalStorage.set(ACCEPT_COOKIE_STORAGE_KEY, true);
                        setAccept(true);
                    } }, { children: t('common:acceptCookie.accept') }))] })) })));
};
