import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import Router from 'next/router';
export const MobileBackHeader = (props) => {
    const { className, title, path, rightRender, icon } = props;
    const navigateToPath = () => {
        if (!path)
            return Router.back();
        Router.push(path);
    };
    return (_jsxs("div", Object.assign({ className: classNames('flex justify-between items-center h-11 bg-white', 'pc:hidden', className) }, { children: [_jsxs("div", Object.assign({ className: "flex text-base text-black-1 font-medium", onClick: navigateToPath }, { children: [icon, title] })), _jsx("div", { children: rightRender })] })));
};
