import { MessageApi } from '@common/api/message';
import { useUser } from '@common/application/store/user';
import { ROUTE_MAP } from '@common/constants/route-map';
import classnames from 'classnames';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

export const Notice: React.FC<
  React.PropsWithStyle<{
    dark?: boolean;
  }>
> = ({ className, dark }) => {
  const [count, setCount] = useState(0);
  const { user } = useUser();

  useEffect(() => {
    MessageApi.count().then((res) => {
      setCount(res.count);
    });
  }, [user]);

  return (
    <Link href={ROUTE_MAP.MESSAGE}>
      <span
        className={classnames('w-6 h-6 pc:w-8 pc:h-8 relative grid place-items-center', className)}
      >
        {dark ? (
          <BlackLineIcon className="w-full h-full" icon="notice" />
        ) : (
          <WhiteLineIcon className="w-full h-full" icon="notice" />
        )}
        {Boolean(count) && (
          <div className="absolute -right-2 -top-2 grid place-items-center min-w-4 h-4 text-xs text-white bg-[#FF9B26] rounded-full">
            {count}
          </div>
        )}
      </span>
    </Link>
  );
};
