import { useMemoizedFn } from 'ahooks';
import { isBoolean, isNil } from 'lodash-es';
import { useContext } from 'react';
import { useFormItem } from '../form';
import { RadioGroupContext } from './context';
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useMergedRadioProps(props) {
    var _a, _b, _c, _d, _e, _f, _g;
    const group = useContext(RadioGroupContext);
    const formItem = useFormItem();
    if (!group.isDefault && isNil(props.value)) {
        throw new Error('[Radio]: props 中缺少 value 属性');
    }
    // 选中状态 优先级 current >  group > formItem
    const checked = isBoolean(props.checked)
        ? props.checked
        : !group.isDefault
            ? group.getChecked(props.value)
            : !formItem.isDefault
                ? formItem.control.value
                : undefined;
    // 禁用 优先级 current >  group > formItem
    const disabled = isBoolean(props.disabled)
        ? props.disabled
        : !group.isDefault
            ? group.disabled
            : !formItem.isDefault
                ? formItem.disabled
                : undefined;
    // 改变时的回调 优先级 current >  group > formItem
    const onChange = useMemoizedFn((event) => {
        event.target.checked = true;
        if (props.onChange) {
            props.onChange(event);
        }
        else if (!group.isDefault) {
            group.changeChecked(props.value);
        }
        else if (!formItem.isDefault) {
            formItem.control.onChange(event.target.checked);
        }
    });
    return Object.assign(Object.assign({}, props), { className: (_a = props.className) !== null && _a !== void 0 ? _a : group.className, checkedClassName: (_b = props.checkedClassName) !== null && _b !== void 0 ? _b : group.checkedClassName, iconClassName: (_c = props.iconClassName) !== null && _c !== void 0 ? _c : group.iconClassName, iconCheckedClassName: (_d = props.iconCheckedClassName) !== null && _d !== void 0 ? _d : group.iconCheckedClassName, contentClassName: (_e = props.contentClassName) !== null && _e !== void 0 ? _e : group.contentClassName, contentCheckedClassName: (_f = props.contentCheckedClassName) !== null && _f !== void 0 ? _f : group.contentCheckedClassName, name: (_g = props.name) !== null && _g !== void 0 ? _g : group.name, checked,
        disabled,
        onChange });
}
