import { classNames } from '@common/application/utils/classnames';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

interface Props {
  className?: string;
  titleClassName?: string;
  title: string;
  children?: React.ReactNode;
  ontTitleClick?: () => void;
}
export const Card = ({ title, className, children, ontTitleClick, titleClassName }: Props) => {
  return (
    <div className={classNames('w-full text-base pad:text-sm', className)}>
      <div
        className={classNames(
          'flex justify-between items-center w-full pt-4 pb-2',
          'pc:pt-0 pc:pb-1 pc:border-0',
          {
            'cursor-pointer': !!ontTitleClick
          },
          titleClassName
        )}
        onClick={() => ontTitleClick?.()}
      >
        <p
          className={classNames(
            'line-clamp-1 text-black-1 font-bold flex-1 text-sm hover:text-primary'
          )}
        >
          {title}
        </p>
        {title && <BlackLineIcon className="w-4 h-4 inline-block pc:hidden" icon="right-arrow" />}
      </div>
      <div>{children}</div>
    </div>
  );
};
