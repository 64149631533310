var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/*
 * @Author: 朱璐
 * @Date: 2022-11-01 18:32:47
 * @Description: 商品列表store
 */
import { CategoryApi } from '@common/api/category';
import { ProductListApi } from '@common/api/product-list';
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { cleanParams } from '@common/application/utils/common';
import { createPath } from '@common/application/utils/create-path';
import { emitPlpFilterUpdate, emitUpdatePriceFilter } from '@common/application/utils/event';
import { scrollTop } from '@common/application/utils/scroll-top';
import { PRODUCT_LIST_SORT } from '@common/constants/product-list';
import { ROUTE_MAP } from '@common/constants/route-map';
import { debounce, find, get, isEmpty, isEqual, last } from 'lodash-es';
import Router, { useRouter } from 'next/router';
import qs from 'qs';
import { atom, useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { RECOIL_KEY } from './key';
/** 筛选候选项 */
export const productListBaseFilter = atom({
    key: RECOIL_KEY.PRODUCT_LIST_BASE_FILTER
});
/** 分类 */
export const productListCategory = atom({
    key: RECOIL_KEY.PRODUCT_LIST_CATEGORY
});
/** 默认筛选项 */
export const PRODUCT_LIST_PARAMS_DEFAULT = {
    brands: [],
    keywords: '',
    sort: PRODUCT_LIST_SORT.RECOMMEND,
    price: null,
    category: null,
    brandIds: null
};
/** 筛选项值 */
export const productListParams = atom({
    key: RECOIL_KEY.PRODUCT_LIST_FILTER,
    default: PRODUCT_LIST_PARAMS_DEFAULT
});
export const categoryBreadcrumb = atom({
    key: RECOIL_KEY.CATEGORY_BREADCRUMB,
    default: []
});
/** 商品列表 */
export const productList = atom({
    key: RECOIL_KEY.PRODUCT_LIST
});
export const productListBanners = atom({
    key: RECOIL_KEY.PRODUCT_LIST_BANNERS,
    default: []
});
export const productListSourceParams = atom({
    key: RECOIL_KEY.PROUDUCT_LIST_SOURCE_PARAMS,
    default: {}
});
/** 搜索价格初始值 */
export const prodcutListInitPrice = atom({
    key: RECOIL_KEY.PRODUCT_LIST_INIT_PRICE,
    default: { minPrice: '0', maxPrice: '0' }
});
/** 页面类型 */
export const prodcutListPageTypeState = atom({
    key: RECOIL_KEY.PRODUCT_LIST_PAGE_TYPE,
    default: 'search'
});
/** url转搜索条件 */
export const urlToParams = (query, pageType) => {
    const params = {
        keywords: get(query, 'keywords') || get(query, 'q') || '',
        // 品牌页主ID
        brandId: get(query, 'brandId') || '',
        // 品牌筛选项
        brandIds: get(query, 'brandIds') || '',
        // 分类筛选项
        categoryIds: get(query, 'categoryIds') || '',
        promotionId: get(query, 'promotionId') || '',
        merchantId: get(query, 'merchantId') || '',
        price: {},
        sort: get(query, 'sort') || PRODUCT_LIST_PARAMS_DEFAULT.sort,
        page: get(query, 'page') || 1
    };
    const minPrice = get(query, 'minPrice');
    if (minPrice) {
        params.price.minPrice = minPrice;
    }
    const maxPrice = get(query, 'maxPrice');
    if (maxPrice) {
        params.price.maxPrice = maxPrice;
    }
    // 搜索页主ID
    if (pageType === 'displayCategory') {
        params.categoryId = last(query.path);
    }
    else if (pageType === 'virtualCategory') {
        params.virtualCategoryId = last(query.path);
    }
    const productAttributeKeys = get(query, 'productAttributeKeys', '');
    if (productAttributeKeys) {
        productAttributeKeys
            .split(',')
            .filter(Boolean)
            .forEach((value) => {
            const [key] = value.split('#');
            const arr = [];
            productAttributeKeys.split(',').forEach((item) => {
                if (key === item.split('#')[0]) {
                    arr.push(item);
                    params[`attributes-${key}`] = arr;
                }
            });
        });
    }
    return params;
};
/** 搜索后处理url */
export const formatUrl = (_a) => {
    var { keywords, page } = _a, rest = __rest(_a, ["keywords", "page"]);
    const _nParams = Object.assign(Object.assign({}, rest), { q: keywords, page });
    const query = qs.stringify(cleanParams(_nParams));
    const [orgBase] = Router.asPath.split('?');
    const { type, download } = Router.query;
    let finalQuery = query;
    if (type) {
        finalQuery += `&type=${type}`;
    }
    /**
     * 检测是否有下载参数
     */
    if (download) {
        finalQuery += `&download=${download}`;
    }
    Router.replace(`${orgBase}?${finalQuery}`, '', { shallow: true });
};
/** 筛选项转api接口参数 */
export const storeParams2ApiParams = (params) => {
    var _a, _b;
    let formatParams = {
        brandIds: !isEmpty(params.brands)
            ? (params.brands || []).filter(Boolean).join(',')
            : params.brandIds,
        sort: params.sort,
        page: params.page || 1,
        minPrice: (_a = params.price) === null || _a === void 0 ? void 0 : _a.minPrice,
        maxPrice: (_b = params.price) === null || _b === void 0 ? void 0 : _b.maxPrice,
        keywords: params === null || params === void 0 ? void 0 : params.keywords,
        promotionId: (params === null || params === void 0 ? void 0 : params.promotionId) || undefined,
        merchantId: (params === null || params === void 0 ? void 0 : params.merchantId) || undefined,
        categoryIds: (params === null || params === void 0 ? void 0 : params.categoryIds) || undefined
    };
    // 品牌页主ID需要合并到品牌筛选项里一起请求
    if (params.brandId) {
        formatParams.brandIds = [...new Set([params.brandId, params.brandIds])]
            .filter(Boolean)
            .join(',');
    }
    // 展示分类页主ID需要合并到分类筛选项里一起请求
    if (params.categoryId) {
        formatParams.categoryIds = [...new Set([params.categoryId, params.categoryIds])]
            .filter(Boolean)
            .join(',');
    }
    // 虚拟分类页主ID需要合并到分类筛选项里一起请求
    if (params.virtualCategoryId) {
        formatParams.virtualCategoryIds = [
            ...new Set([params.virtualCategoryId, params.virtualCategoryIds])
        ]
            .filter(Boolean)
            .join(',');
    }
    const attributeKey = Object.keys(params).filter((i) => i.indexOf('attributes-') > -1);
    const productAttributeKeys = attributeKey
        .map((key) => params[key])
        .reverse()
        .flat();
    formatParams = Object.assign(Object.assign({}, formatParams), { productAttributeKeys: productAttributeKeys.join(','), offlineCategoryId: (params.offlineCategoryId || []).join(',') });
    return formatParams;
};
/**
 * 搜索商品列表
 * 处理url
 */
export const useSearchProductList = () => {
    const setProductListBaseFilter = useSetRecoilState(productListBaseFilter);
    const [orgParams, setParams] = useRecoilState(productListParams);
    const sourceParams = useRecoilValue(productListSourceParams);
    const setProductList = useSetRecoilState(productList);
    const setCategoryData = useSetRecoilState(productListCategory);
    const { pc } = useBreakpoint();
    const router = useRouter();
    const search = debounce(useRecoilCallback(({ snapshot }) => ({ filterType, _page = 1, scrollType = 'bottom' }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const params = yield snapshot.getPromise(productListParams);
        const products = yield snapshot.getPromise(productList);
        let category;
        const formatParams = storeParams2ApiParams(Object.assign(Object.assign({}, params), { page: _page }));
        // 如果更新了categoryId 则重新请求category数据和面包屑数据
        if (params.categoryId && !isEqual(params.categoryId, orgParams.categoryId)) {
            let categories;
            if (params.categoryId) {
                categories = yield CategoryApi.getListById(params.categoryId, { hideLoading: true });
                category = find(categories, (i) => i.id === params.categoryId);
                setCategoryData(categories);
                setParams((org) => (Object.assign(Object.assign({}, org), { category })));
            }
        }
        /**
         * 触发防止plp筛选项二次更新
         */
        emitPlpFilterUpdate();
        formatUrl(formatParams);
        const newParams = {};
        try {
            // 重置筛选候选项 ['sort','page']
            if (['sort', 'page'].indexOf(filterType) < 0) {
                const filter = yield ProductListApi.getFilters(formatParams, {
                    hideLoading: true
                });
                const query = qs.parse(window.location.search.slice(1));
                const brands = get(query, 'brandIds', undefined);
                const keywords = get(query, 'q', undefined);
                const productAttributeKeys = get(query, 'productAttributeKeys', undefined);
                if (brands || productAttributeKeys) {
                    // 判断是否选中的是品牌，保留品牌筛选项
                    if ((brands && filterType === 'price') ||
                        (keywords && brands && filterType === 'price')) {
                        setProductListBaseFilter((org) => {
                            return Object.assign(Object.assign({}, filter), { [filterType]: org[filterType], brands: org.brands });
                        });
                    }
                    // 判断是否选中的是属性，保留属性筛选项
                    else if (productAttributeKeys || (keywords && productAttributeKeys)) {
                        const value = productAttributeKeys.split(',')[0];
                        let key = '';
                        Object.keys(filter).forEach((k) => {
                            if (k.startsWith('attributes-')) {
                                // @ts-ignore
                                filter[k].attributeValues.forEach((item) => {
                                    if (item.id.includes(value)) {
                                        key = k;
                                    }
                                });
                            }
                        });
                        if (key && key.startsWith('attributes-')) {
                            setProductListBaseFilter((org) => {
                                return Object.assign(Object.assign({}, filter), { [filterType]: org[filterType], [key]: org[key] });
                            });
                        }
                        else {
                            setProductListBaseFilter((org) => {
                                return Object.assign(Object.assign({}, filter), { [filterType]: org[filterType] });
                            });
                        }
                    }
                    else {
                        setProductListBaseFilter((org) => {
                            return Object.assign(Object.assign({}, filter), { [filterType]: org[filterType] });
                        });
                    }
                }
                else {
                    setProductListBaseFilter((org) => {
                        return Object.assign(Object.assign({}, filter), { [filterType]: org[filterType] });
                    });
                }
                /**
                 * 因为筛选数据时，有的数据虽已选中，但是会被后端过滤掉
                 * 但前端属性依旧是选中状态，更新属性筛选项参数，
                 * 导致接口传参时，会出现多的属性筛选项
                 */
                Object.keys(params).forEach((key) => {
                    if (key.startsWith('attributes-') &&
                        // @ts-ignore
                        !isEmpty(filter[key]) &&
                        !isEmpty(params[key])) {
                        newParams[key] = params[key]
                            .map((_item, index) => {
                            if (key.startsWith('attributes-') &&
                                // @ts-ignore
                                params[key].length >= filter[key].attributeValues.length) {
                                // @ts-ignore
                                return filter[key].attributeValues.map((item) => {
                                    if (params[key].includes(item.id)) {
                                        return item.id;
                                    }
                                    return '';
                                })[index];
                            }
                            return params[key][index];
                        })
                            .filter((item) => item);
                    }
                });
                /**
                 * 更新价格区间
                 */
                emitUpdatePriceFilter({
                    minPrice: ((_a = filter.price) === null || _a === void 0 ? void 0 : _a.minPrice) || '0',
                    maxPrice: ((_b = filter.price) === null || _b === void 0 ? void 0 : _b.maxPrice) || '0'
                });
            }
            /**
             * 更新参数
             */
            setParams(Object.assign(Object.assign({}, params), newParams));
            const newFormatParams = storeParams2ApiParams(Object.assign(Object.assign(Object.assign({}, params), newParams), { page: _page }));
            const { result, total, page, totalPage, resultPrice } = yield ProductListApi.getProductList(Object.assign(Object.assign({}, newFormatParams), sourceParams));
            let mobileList = [];
            if (scrollType === 'bottom') {
                mobileList = _page === 1 ? result : products.result.concat(result);
            }
            else {
                mobileList = [...result, ...products.result];
            }
            setProductList({
                total,
                webResult: result,
                result: mobileList,
                page,
                totalPage,
                resultPrice
            });
            if (pc && filterType === 'page')
                scrollTop({ duration: 1000 });
        }
        catch (e) {
            const errorData = get(e, 'data', {});
            if (errorData && errorData.errMsg && errorData.errCode === 500009) {
                router.push(createPath(ROUTE_MAP.HOME));
            }
        }
    })), 500);
    return {
        search
    };
};
