import { userState } from '@common/application/store/user';
import { classNames } from '@common/application/utils/classnames';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';

import { IMG_LOGO } from '@/resources/images';

export const Logo = ({ className }: React.PropsWithStyle) => {
  const user = useRecoilValue(userState);

  return (
    <div className={classNames('w-full h-full grid place-items-center', className)}>
      <Link href={ROUTE_MAP.HOME}>
        {user?.source && user?.webLogo ? (
          <div className={classNames('relative w-25 h-9', 'pc:w-32 pc:h-11.5')}>
            <DImage src={user?.webLogo} className="w-full h-full" layout="fill" alt="" />
          </div>
        ) : (
          <DImage src={IMG_LOGO} alt="" />
        )}
      </Link>
    </div>
  );
};

Logo.defaultProps = {
  type: 'white'
};
