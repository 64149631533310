import { CommonApi } from '@common/api/common';
import { useAddSearchHistory } from '@common/application/store/search';
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { emitPopState } from '@common/application/utils/event';
import { ROUTE_MAP } from '@common/constants/route-map';
import { useDebounce } from 'ahooks';
import { isEmpty } from 'lodash-es';
import Router from 'next/router';
import { useEffect, useState } from 'react';

interface Props {
  keyword: string;
  onListChange?: (list: string[]) => void;
}

export const SearchPrediction = ({ keyword, onListChange }: Props) => {
  const [predictionList, setPredictionList] = useState<string[]>([]);
  const handleAddSearchHistory = useAddSearchHistory();
  const debouncedValue = useDebounce(keyword, { wait: 250 });

  const setList = (list: string[]) => {
    onListChange?.(list);
    setPredictionList(list);
  };

  const handleSearch = (value: string) => {
    handleAddSearchHistory(value);
    Router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: value }));
  };

  useEffect(() => {
    if (isEmpty(debouncedValue)) return setList([]);
    CommonApi.getPredictionWords({ keywords: debouncedValue }).then(({ cskuNameList }) =>
      setList(cskuNameList)
    );
    // 触发搜索plp滚动到目标位置
    emitPopState();
  }, [debouncedValue]);

  return isEmpty(predictionList) ? (
    <></>
  ) : (
    <div className={classNames('w-full')}>
      {predictionList?.map((item, index) => (
        <div
          key={index}
          className={classNames(
            'flex items-center cursor-pointer text-black-1 text-sm border-b border-black-6 h-13',
            'px-3',
            'pc:px-4'
          )}
          onClick={() => handleSearch(item)}
        >
          <span className="line-clamp-1">{item}</span>
        </div>
      ))}
    </div>
  );
};
