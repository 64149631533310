import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const ColorFillIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    success: 1,
    error: 2,
    warning: 3,
    'success-light': 4,
    'error-light': 5,
    add: 6,
    'address-selected': 7,
    like: 8
  }
});
