/*
 * @Author: 朱璐
 * @Date: 2022-10-28 11:12:47
 * @Description: Footer
 */

import { classNames } from '@common/application/utils/classnames';
import { FloatingButtons } from '@common/components/common/floating-buttons';
import { DImage } from '@common/components/common/image';
import LinkOrDiv from '@common/components/common/link-or-div';
import { cloneDeep } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { IconArrow } from '@/components/icon/arrow';
import { GlobalConfig } from '@/constants/global-config';
import { IMG_E_SHOP } from '@/resources/images';
import { FeatureIcon } from '@/resources/sprites-images/feature-icon';
import { PayIcon } from '@/resources/sprites-images/pay-icon';

interface Props {
  children?: React.ReactNode;
}

const LogoContainer = () => {
  const { t } = useTranslation(['common']);

  const NAMES_LIST = [
    { id: 1, name: t('enterpriseQuality'), icon: 'quality' },
    { id: 2, name: t('secureShopping'), icon: 'secure' },
    { id: 3, name: t('giveAway'), icon: 'deliver-goods' },
    { id: 4, name: t('pickupLocations'), icon: 'store' }
  ];

  return (
    <div className={classNames('w-full container mx-auto')}>
      <div className={classNames('min-h-18 flex items-center', 'py-5', 'pc:py-0')}>
        <div
          className={classNames('w-full flex items-center flex-row justify-around', 'flex-wrap')}
        >
          {NAMES_LIST.map((item) => (
            <section
              className={classNames('flex items-center', 'w-41.25 h-10.25', 'pc:h-13 pc:w-72.75')}
              key={item.id}
            >
              <div className={classNames('mr-2', 'pc:mr-3.5')}>
                <FeatureIcon className="w-6 h-6 pc:w-9 pc:h-9" icon={item.icon} />
              </div>
              <div className={classNames('text-white', 'text-xs', 'pc:text-sm')}>{item.name}</div>
            </section>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          'h-0.25 w-full',
          'border-0',
          'pc:border-b pc:border-white pc:opacity-10'
        )}
      ></div>
    </div>
  );
};

const MobileLink: React.FC<Props> = ({ children }) => {
  const [expand, setExpand] = useState(new Set());
  const { t } = useTranslation(['common']);

  const { footerLink: link } = GlobalConfig();
  return (
    <div className="relative w-full container mx-auto">
      <div className="w-full">
        {link.map((item, index) => (
          <div
            key={index}
            className={classNames('w-full flex flex-col text-white text-xs border-b', {
              'border-y': index === 0
            })}
            style={{ borderColor: 'rgba(255, 255, 255, 0.1)' }}
          >
            <div
              className="flex items-center justify-between py-5"
              onClick={() => {
                if (expand.has(index)) {
                  setExpand((org) => {
                    const _org = cloneDeep(org);
                    _org.delete(index);
                    return _org;
                  });
                } else {
                  setExpand((org) => {
                    const _org = cloneDeep(org);
                    _org.add(index);
                    return _org;
                  });
                }
              }}
            >
              <span>{t(item.title)}</span>
              <IconArrow
                className={classNames('rotate-180', { 'rotate-[360deg]': expand.has(index) })}
              />
            </div>
            {expand.has(index) &&
              item.list.map((item, index) => (
                <LinkOrDiv href={item.href} key={`${item.text}-${index}`}>
                  <div className="mb-3 text-white opacity-50 text-xs cursor-pointer">
                    {t(item.text)}
                  </div>
                </LinkOrDiv>
              ))}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

const WebLink: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation(['common']);
  const { footerLink: link } = GlobalConfig();
  return (
    <div className="relative w-full container mx-auto">
      <div className="grid grid-cols-12 pt-10">
        <div className="flex justify-between col-start-1 col-end-5">
          {link.map((item, index) => (
            <div key={index} className="flex flex-col text-white text-base">
              <div
                className={classNames('flex items-center mb-3 cursor-pointer', {
                  'justify-center': index !== 0
                })}
              >
                <span>{t(item.title)}</span>
              </div>
              {item.list.map((item, i) => (
                <LinkOrDiv key={`${item.text}-${i}`} href={item.href}>
                  <div
                    className={classNames('mt-3 text-white opacity-50 text-sm cursor-pointer', {
                      'text-center': index !== 0
                    })}
                  >
                    {t(item.text)}
                  </div>
                </LinkOrDiv>
              ))}
            </div>
          ))}
        </div>
        {children}
      </div>
    </div>
  );
};

/**
 * 酒类以及支付卡片展示
 */
const CommonEle = () => {
  const { t } = useTranslation(['common']);
  const { footerPayLogo } = GlobalConfig();
  return (
    <>
      <div className={classNames('px-15 mt-6', 'pc:col-start-6 pc:col-end-9 pc:px-0 pc:mt-0')}>
        <div className={classNames('text-center text-white', 'text-sm', 'pc:text-base')}>
          {t('liquor')}
        </div>
        <div className={classNames('text-white mt-4', 'text-2xs', 'pc:text-xs')}>
          <p className={classNames('text-center opacity-50')}>{t('law')}</p>
          {/* <p className={classNames('break-words w-full text-center opacity-50')}>
            {t('lawEnglist')}
          </p> */}
        </div>
      </div>
      <div className={classNames('mt-6', 'pc:col-start-10 pc:col-end-13 pc:mt-0')}>
        <div
          className={classNames(
            'grid grid-cols-5 gap-y-3.25',
            'pc:place-content-start pc:grid-rows-2 pc:grid-cols-4 pc:gap-x-4 pc:gap-y-3'
          )}
        >
          {footerPayLogo.map((item, index) => (
            <LinkOrDiv href={item.href} key={index}>
              <div className={classNames('w-13.5 h-9.5', 'pc:w-15 pc:h-10.5')}>
                <PayIcon
                  className="w-full h-full rounded-sm pc:hidden"
                  aspectRatio="13.5/9.5"
                  icon={item.icon}
                />
                <PayIcon
                  className="hidden pc:block w-full h-full rounded-sm"
                  aspectRatio="15/10.5"
                  icon={item.icon}
                />
              </div>
            </LinkOrDiv>
          ))}
        </div>
        <div className={classNames('felx w-full', 'mt-3', 'pc:mt-3.25')}>
          <DImage
            src={IMG_E_SHOP}
            imgClassName={classNames('w-34.75 h-13.5')}
            className={classNames('w-full', 'justify-center', 'pc:justify-start')}
          />
        </div>
      </div>
    </>
  );
};

export const Footer = ({
  className,
  floatingButtonClassName
}: {
  className?: string;
  floatingButtonClassName?: string;
}) => {
  const { t } = useTranslation(['common']);

  return (
    <footer className={classNames('relative w-full bg-black-2', className)}>
      (
      <>
        <div className="pc:block mobile:hidden">
          <LogoContainer />
          <WebLink>
            <CommonEle />
          </WebLink>
        </div>

        <div className="pc:hidden mobile:block">
          <LogoContainer />
          <MobileLink>
            <CommonEle />
          </MobileLink>
        </div>

        <div className={classNames('h-0.25 w-full mt-10 border-b border-white opacity-10')}></div>
        <div
          className={classNames(
            'text-white block text-center my-3 opacity-50',
            'px-12 text-2xs',
            'pc:px-0 pc:text-xs'
          )}
        >
          {t('login.footText.copyright')}
        </div>
      </>
      )
      <FloatingButtons className={floatingButtonClassName} />
    </footer>
  );
};
