import { defaults, pick } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { FormContext, FormItemContext } from './context';
/**
 * 获取表单上下文
 */
export function useForm() {
    return useContext(FormContext);
}
/**
 * 获取表单单元项上下文
 */
export function useFormItem() {
    return useContext(FormItemContext);
}
const defaultFormItemProps = {
    className: '',
    errorClassName: '',
    labelClassName: '',
    labelErrorClassName: '',
    inputClassName: '',
    inputErrorClassName: ''
};
/**
 * 获取合并处理过的表单单元项 props
 */
export function useFormItemProps(props) {
    const form = useForm();
    return useMemo(() => {
        const propsOfForm = pick(form, [
            'className',
            'errorClassName',
            'labelClassName',
            'labelErrorClassName',
            'inputClassName',
            'inputErrorClassName',
            'colon'
        ]);
        return defaults({}, defaultFormItemProps, propsOfForm, props);
    }, [form, props]);
}
