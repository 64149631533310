import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const FeatureIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    'deliver-goods': 1,
    quality: 2,
    secure: 3,
    store: 4
  }
});
