import { useUser } from '@common/application/store/user';
import { globalAuth } from '@common/application/utils/auth';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import Link from 'next/link';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

export const User: React.FC<{
  dark?: boolean;
}> = ({ dark }) => {
  const { user } = useUser();

  return user?.id ? (
    <Link href={ROUTE_MAP.ACCOUNT}>
      <span
        className="w-6 h-6 pc:w-8 pc:h-8 relative grid place-items-center cursor-pointer"
        data-testid="test-account"
      >
        {user.picUrl ? (
          <DImage className="w-6 h-6 pc:w-8 pc:h-8" src={user.picUrl} fill />
        ) : dark ? (
          <BlackLineIcon className="w-full h-full" icon="user" />
        ) : (
          <WhiteLineIcon className="w-full h-full" icon="user" />
        )}
      </span>
    </Link>
  ) : (
    <a
      onClick={() => globalAuth.login()}
      className="w-6 h-6 pc:w-8 pc:h-8 relative grid place-items-center cursor-pointer"
    >
      {dark ? (
        <BlackLineIcon className="w-full h-full" icon="user" />
      ) : (
        <WhiteLineIcon className="w-full h-full" icon="user" />
      )}
    </a>
  );
};
