import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { isArray } from 'lodash-es';
/**
 * 促销标签以及商品标签
 */
export const ProductLabel = ({ labelData, className, type = 'product' }) => {
    if (!labelData) {
        return _jsx(_Fragment, {});
    }
    const data = type === 'product' ? labelData.split(' ').filter((item) => item !== '') : labelData;
    return (_jsx(_Fragment, { children: isArray(data) ? (data.map((item, index) => (_jsx("div", Object.assign({ className: classNames('px-0.5 text-px', 'pc:px-1 pc:text-1xs', className) }, { children: item }), index)))) : (_jsx("div", Object.assign({ className: classNames('px-0.5 text-px', 'pc:px-1 pc:text-1xs', className) }, { children: data }))) }));
};
