import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const WhiteLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    user: 1,
    notice: 2,
    cart: 3,
    car: 4,
    'top-triangle': 5,
    'bottom-triangle': 6,
    search: 7,
    'cart-plus': 8,
    store: 9,
    delete: 10,
    x: 11
  }
});
