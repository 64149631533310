var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-03 11:30:40
 * @Description: 排序组件
 */
import { productListParams, useSearchProductList } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import Select from '@common/components/common/select';
import { PRODUCT_LIST_SORT } from '@common/constants/product-list';
import { get } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
export const Sort = ({ className, customerArrow, solideArrow = true }) => {
    const [filter, setFilter] = useRecoilState(productListParams);
    const { t } = useTranslation(['product-list']);
    const { search } = useSearchProductList();
    const router = useRouter();
    useEffect(() => {
        const sort = get(router, 'query.sort', undefined);
        if (sort) {
            setFilter((org) => (Object.assign(Object.assign({}, org), { sort: sort })));
        }
    }, []);
    const handleChange = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setFilter((org) => (Object.assign(Object.assign({}, org), { sort: data })));
        search({ filterType: 'sort' });
    });
    const SORT_OPTIONS = [
        {
            id: PRODUCT_LIST_SORT.RECOMMEND,
            name: t('sortOptions.1')
        },
        {
            id: PRODUCT_LIST_SORT.CREATE_TIME_DESC,
            name: t('sortOptions.6')
        },
        {
            id: PRODUCT_LIST_SORT.SALES_VOLUME_DESC,
            name: t('sortOptions.4')
        },
        {
            id: PRODUCT_LIST_SORT.PRICE_DESC,
            name: t('sortOptions.2')
        },
        {
            id: PRODUCT_LIST_SORT.PRICE_ASC,
            name: t('sortOptions.3')
        },
        {
            id: PRODUCT_LIST_SORT.RATING_DESC,
            name: t('sortOptions.8')
        }
    ];
    const selectedOption = useMemo(() => {
        return SORT_OPTIONS.find((item) => item.id === filter.sort);
    }, [filter]);
    return (_jsx("div", Object.assign({ className: classNames('flex items-center h-7.5 text-sm text-black-2 cursor-pointer', 'pc:h-full', className) }, { children: _jsx(Select, { selectClassName: "text-primary bg-transparent border-none shadow-none pr-0", overlayClassName: "-mt-3 w-43 max-h-full text-black-2 rounded", selectItemClassName: "justify-center h-11 border-black-8", placeholderClassName: "text-black-2", options: SORT_OPTIONS, value: selectedOption, placeholder: SORT_OPTIONS[0].name, onChange: (v) => handleChange(v.id), solideArrow: solideArrow, renderSolideArrow: (visible) => {
                if (customerArrow)
                    return customerArrow(visible);
                return _jsx(_Fragment, {});
            } }) })));
};
Sort.defaultProps = {
    solideArrow: true
};
