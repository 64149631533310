import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useClientValue } from '@common/application/hooks/use-client-value';
import { useScrollLock } from '@common/application/hooks/use-scroll-lock';
import { pageHomeCategory } from '@common/application/store/category';
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { usePdpBtnFixedListener } from '@common/application/utils/event';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { ICategory } from '@common/types/category';
import { useEventListener } from 'ahooks';
import Router from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

import { FirstCategory } from './first-category';
import { useCategory } from './use-category';

interface Props {
  open: boolean;
  top: number;
  onHide: (callback?: () => void) => void;
}

const FirstCategoryItem = ({
  item,
  activeCategory,
  onHover,
  onClick,
  index
}: {
  item: ICategory;
  activeCategory?: ICategory;
  onHover: (item: ICategory) => void;
  onClick: (item: ICategory) => void;
  index: number;
}) => {
  return (
    <li
      key={item.id}
      className={classNames(
        'relative h-11.5 flex items-center border-none pad:border-t border-rose-700 pad:px-6 cursor-pointer first:border-none',
        'text-center',
        'pc:text-left',
        {
          'bg-white': activeCategory?.id === item.id
        }
      )}
      style={{ width: `calc(100%)` }}
      onMouseEnter={() => onHover(item)}
      data-testid={`test-category-${index}`}
    >
      <a
        href={createPath(
          ROUTE_MAP.CATEGORY_LIST,
          {
            category: item.name,
            id: item.id
          },
          { withLocale: true }
        )}
        className={classNames(
          'w-full pad:w-auto items-center justify-center line-clamp-2 cursor-pointer text-black-3',
          'text-[13px]',
          'pc:text-base',
          'hover:text-primary',
          ...(activeCategory?.id === item.id ? ['text-primary pad:text-primary'] : [])
        )}
        onClick={(e) => {
          e.preventDefault();
          onClick(item);
        }}
      >
        <div className="w-full h-full line-clamp-2">{item.name}</div>
      </a>
    </li>
  );
};

const CATEGORY_HEIGHT = 600;
const DISTANCE_BOTTOM = 30;

export const GoodsCategory = (props: Props) => {
  const { categoryList: categoryData } = useRecoilValue(pageHomeCategory);
  const { open, top, onHide } = props;

  const subRef = useRef(null);

  const [firstData, setFirstData] = useState<ICategory>();

  const [activeCategory, setActiveCategory] = useState<ICategory>();

  const [viewportHeight, setViewportHeight] = useState<number>(0);
  const [pdpBtnHeight, setPdpBtnHeight] = useState<number>(0);

  const { onMenuMouseEnter, onWrapMouseMove, onRowMouseEnter, onMenuMouseLeave } =
    useCategory<ICategory>({
      subRef,
      active: (item) => {
        setActiveCategory(item);
        setFirstData(item);
      }
    });

  const screens = useBreakpoint();

  const firstCatClick = async (data: ICategory) => {
    if (screens.mobile && data.childTree.length) return;
    onHide();
    Router.push(
      createPath(ROUTE_MAP.CATEGORY_LIST, {
        category: data.name,
        id: data.id
      })
    );
  };

  const setDefaultChecked = () => {
    if (!categoryData.length) return;
    setFirstData(categoryData[0]);
    setActiveCategory(categoryData[0]);
  };

  useEffect(() => {
    if (open && categoryData.length) {
      setDefaultChecked();
    }
  }, [open, categoryData]);

  const bodyEl = useClientValue(() => document.body);
  const [, toggle] = useScrollLock(bodyEl);

  useEffect(() => toggle(!!open), [open]);

  usePdpBtnFixedListener(({ btnHeight }) => {
    setPdpBtnHeight(btnHeight);
  });

  useEventListener(
    'resize',
    () => {
      setViewportHeight(window.innerHeight);
    },
    { passive: true }
  );

  useEffect(() => {
    setViewportHeight(window.innerHeight);
  }, []);

  const categoryHeight = useMemo(() => {
    if (viewportHeight - top - pdpBtnHeight < CATEGORY_HEIGHT) {
      return `calc(100% - ${top + DISTANCE_BOTTOM + pdpBtnHeight}px)`;
    }
    return '600px';
  }, [viewportHeight, top, pdpBtnHeight]);

  return (
    <div
      className={classNames('fixed left-0 z-20 w-full border-t border-[#e5e5e5]', {
        hidden: !open
      })}
      style={{
        top,
        height: screens.pc ? categoryHeight : `calc(100% - ${top}px)`
      }}
    >
      <div className="pc:container pad:container h-full">
        <div
          onMouseMove={onWrapMouseMove}
          className={classNames('grid grid-cols-12 gap-3 h-full relative')}
          onMouseLeave={() => {
            onHide(() => {
              setActiveCategory(undefined);
              setFirstData(undefined);
            });
          }}
        >
          <ul
            className={classNames(
              'overflow-y-auto overflow-x-hidden bg-black-8 relative h-full',
              'col-span-3',
              'pc:col-span-2'
            )}
          >
            {categoryData.map((item, index) => (
              <FirstCategoryItem
                onClick={firstCatClick}
                key={item.id}
                item={item}
                activeCategory={activeCategory}
                onHover={onRowMouseEnter}
                index={index}
              />
            ))}
            <div className="absolute bottom-6 right-3 block pc:hidden" onClick={() => onHide()}>
              <BlackLineIcon className="w-5 h-5" icon="category-close" />
            </div>
          </ul>
          {firstData && (
            <div
              ref={subRef}
              className={classNames(
                'shadow-md -ml-3 bg-white overflow-y-auto',
                'col-span-9',
                'pc:col-span-10'
              )}
              onMouseEnter={onMenuMouseEnter}
              onMouseLeave={onMenuMouseLeave}
            >
              <FirstCategory key={firstData.id} data={firstData.childTree} firstData={firstData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
