export const USER_STORAGE_KEY = 'user';
export const ACCESS_TOKEN_KEY = 'access-token';
export const LOGIN_USERNAME = 'login-username';
/** cookie 和 storage存储的前缀 */
export const STORAGE_PREFIX = 'dchliving-';
/** 商品上下架 */
export var PRODUCT_SHELVES;
(function (PRODUCT_SHELVES) {
    /** 上架 */
    PRODUCT_SHELVES[PRODUCT_SHELVES["ON"] = 1] = "ON";
    /** */
    PRODUCT_SHELVES[PRODUCT_SHELVES["OFF"] = 2] = "OFF";
})(PRODUCT_SHELVES || (PRODUCT_SHELVES = {}));
export var PRODUCT_SOURCE_TYPE;
(function (PRODUCT_SOURCE_TYPE) {
    /**
     * 自建
     */
    PRODUCT_SOURCE_TYPE[PRODUCT_SOURCE_TYPE["SELF_BUILT"] = 0] = "SELF_BUILT";
    /**
     * 标准
     */
    PRODUCT_SOURCE_TYPE[PRODUCT_SOURCE_TYPE["STANDARD"] = 1] = "STANDARD";
    /**
     * 组合
     */
    PRODUCT_SOURCE_TYPE[PRODUCT_SOURCE_TYPE["COMBINATION"] = 2] = "COMBINATION";
    /**
     * 虚拟
     */
    PRODUCT_SOURCE_TYPE[PRODUCT_SOURCE_TYPE["VIRTUAL"] = 3] = "VIRTUAL";
})(PRODUCT_SOURCE_TYPE || (PRODUCT_SOURCE_TYPE = {}));
/**
 * 断点信息
 */
export const breakpoints = {
    mobile: 0,
    pad: 960,
    pc: 1024
};
/**
 * 门店编码
 */
export const ONLINE_STORE_CODE_STORAGE_KEY = 'store-code';
/**
 * 门店 ID
 */
export const ONLINE_STORE_ID_STORAGE_KEY = 'store_id';
/**
 * 门店选择器一级地址缓存
 */
export const STORES_PARENT_STORAGE_KEY = 'store_parent_region';
/**
 * 门店选择器三级地址缓存
 */
export const STORES_CHILD_STORAGE_KEY = 'store_child_region';
/**
 * 当前处于客户端
 */
export const IS_CLIENT = typeof window !== 'undefined';
/**
 * 切换门店Tab
 */
export var SWITCH_TAB_INDEX;
(function (SWITCH_TAB_INDEX) {
    /**
     * 自取
     */
    SWITCH_TAB_INDEX[SWITCH_TAB_INDEX["HOME_DELIVERY"] = 1] = "HOME_DELIVERY";
    /**
     * 送货
     */
    SWITCH_TAB_INDEX[SWITCH_TAB_INDEX["PICK_UP_STORE"] = 0] = "PICK_UP_STORE";
})(SWITCH_TAB_INDEX || (SWITCH_TAB_INDEX = {}));
/**
 *  搜索歷史
 */
export const SEARCH_HISTORY_KEY = 'search_history';
/**
 * 会员门店url
 */
export const MEMBER_STORE_URL = 'elite';
/**
 * 当前选中站点语言
 */
export const WEBSITE_LANGUAGE = 'website-language';
/**
 * 用户接受 cookie
 */
export const ACCEPT_COOKIE_STORAGE_KEY = 'accept-cookie';
/** 触发RN加车 */
export const ADD_CART = 'addCart';
/** 月份Map */
export const MonthsMap = new Map([
    [1, 'Jan'],
    [2, 'Feb'],
    [3, 'Mar'],
    [4, 'Apr'],
    [5, 'May'],
    [6, 'Jun'],
    [7, 'Jul'],
    [8, 'Aug'],
    [9, 'Sep'],
    [10, 'Oct'],
    [11, 'Nov'],
    [12, 'Dec']
]);
/**
 * PDP使用浏览器返回时状态
 */
export const POP_STATET = 'popState';
/**
 * 页面滚动的距离
 */
export const SCROLL_DISTANCE = 'scrollDistace';
/**
 * 商品卡购物车数量缓存
 */
export const PRODUCT_CART_COUNT = 'productCartCount';
/**
 * app ios下载地址
 */
export const IOS_STORE_URL = 'itms-apps://itunes.apple.com/app/id6461726958';
/**
 * app android下载地址
 * */
export const ANDROID_STORE_URL = 'https://play.google.com/store/apps/details?id=com.dch.dchliving';
/**
 * 跳转地址
 */
export const DOWNLOAD_MIDDLEWARE_URL = 'https://www.dchliving.com/app';
