import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { globalConfig } from '@common/application/store/global';
import modifyURL from '@common/application/utils/handle-canonical-url';
import { IS_CLIENT } from '@common/constants/common';
import { PATH_NAME_MAP } from '@common/constants/route-map';
import { get, isEmpty, isString } from 'lodash-es';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { i18n, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
export const PageTitle = ({ pageSEO }) => {
    const { t } = useTranslation();
    const defaultConfig = useRecoilValue(globalConfig);
    const router = useRouter();
    const Header = useMemo(() => {
        var _a, _b, _c;
        const local = (i18n === null || i18n === void 0 ? void 0 : i18n.language) || 'tc';
        const p = router.asPath.slice(1);
        const pathName = get(PATH_NAME_MAP, router.route, 'homePage');
        const { domain } = defaultConfig;
        let canonicalURL = (pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.isPlp)
            ? modifyURL(`${domain}/${local}/${p}`)
            : `${domain}/${local}/${p}`;
        /** 处理首页的canonicalURL多余参数 */
        if (pathName === 'homePage') {
            canonicalURL = `https://${domain === null || domain === void 0 ? void 0 : domain.host}/${local}`;
        }
        let description = isString(pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.description) ? pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.description : '';
        if ((pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.description) && ((_a = pageSEO.description) === null || _a === void 0 ? void 0 : _a.key)) {
            description = t((_b = pageSEO.description) === null || _b === void 0 ? void 0 : _b.key, (_c = pageSEO.description) === null || _c === void 0 ? void 0 : _c.options);
        }
        const title = (pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.useT) && (pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.title) && !(pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.isCampaign) ? t(pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.title) : pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.title;
        const keyword = (pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.useT) && (pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.keywords) ? t(pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.keywords) : pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.keywords;
        let pageTitle = title && title !== t('common:pageTitle.homePage')
            ? t('common:pageTitle.productListingPage', {
                categoryName: title
            })
            : t('common:pageTitle.homePage');
        if (pathName === 'myAccount') {
            pageTitle = t('common:pageTitle.myAccount');
        }
        if (IS_CLIENT)
            window.document.title = pageTitle;
        return (_jsxs(_Fragment, { children: [_jsx("title", { children: pageTitle }), !isEmpty(pageSEO === null || pageSEO === void 0 ? void 0 : pageSEO.title) && _jsx("meta", { name: "title", content: title }), !isEmpty(keyword) && _jsx("meta", { name: "keywords", content: keyword }), !isEmpty(description) && _jsx("meta", { name: "description", content: description }), _jsx("meta", { name: "robots", content: "INDEX, FOLLOW" }), _jsx("link", { rel: "canonical", href: canonicalURL })] }));
    }, [router.asPath]);
    return (_jsxs(Head, { children: [_jsx("meta", { name: "viewport", content: "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no" }), Header] }));
};
