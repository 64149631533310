import 'swiper/swiper-bundle.css';

import { CommonApi } from '@common/api/common';
import { useInRN } from '@common/application/hooks/use-in-rn';
import { useSSO } from '@common/application/store/common';
import { useUser } from '@common/application/store/user';
import { globalAuth } from '@common/application/utils/auth';
import { classNames } from '@common/application/utils/classnames';
import Select from '@common/components/common/select';
import { WEBSITE_LANGUAGE } from '@common/constants/common';
// import env from '@common/constants/env';
import { LANGUAGE_NAMES, LANGUAGE_OPTIONS, LANGUAGE_VALUES } from '@common/constants/language';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { NoticeType } from '@common/types/common';
import axios from 'axios';
import { find, isEmpty } from 'lodash-es';
import NextRouter, { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { WhiteOutlineIcon } from '@/resources/sprites-images/white-outline-icon';

interface Props {
  className?: string;
  showUser?: boolean;
}

interface LanguageItem {
  id: LANGUAGE_VALUES;
  name: string;
}

// const SiteList = [
//   { id: '1', name: 'DCH Living' },
//   { id: '2', name: 'Foodmart', url: env.FOODMART_SITE },
//   { id: '3', name: 'Ahaa', url: env.AHAA_SITE }
// ];

export const noticeHeaderId = 'notice-header';

export const HeaderNotice: React.FC<Props> = ({ className = '', showUser = true }) => {
  const router = useRouter();
  const inRN = useInRN();
  const sso = useSSO();

  const [noticeList, setNoticeList] = useState<NoticeType[]>([]);
  const [language, setLanguage] = useState<{ id: LANGUAGE_VALUES; name: string }>(
    LANGUAGE_OPTIONS[0]
  );

  const { t } = useTranslation(['common']);
  const { user } = useUser();

  const changeLanguage = (value: LanguageItem) => {
    if (value.id === LANGUAGE_VALUES.EN) {
      setLanguage({ id: LANGUAGE_VALUES.TC, name: LANGUAGE_NAMES[LANGUAGE_VALUES.TC] });
    } else if (value.id === LANGUAGE_VALUES.TC || value.id === LANGUAGE_VALUES.CN) {
      setLanguage({ id: LANGUAGE_VALUES.EN, name: LANGUAGE_NAMES[LANGUAGE_VALUES.EN] });
    } else {
      setLanguage({ id: LANGUAGE_VALUES.TC, name: LANGUAGE_NAMES[LANGUAGE_VALUES.TC] });
    }
  };

  useEffect(() => {
    async function getData() {
      const noticeMsgList = await CommonApi.getNoticeMsgList({
        type: 1
      });

      setNoticeList(noticeMsgList);
    }
    getData();

    const local = find(LANGUAGE_OPTIONS, {
      id: router.locale
    }) as LanguageItem;

    if (local) changeLanguage(local);
  }, []);

  const selectLanguage = async (value: { id: LANGUAGE_VALUES; name: string }) => {
    setLanguage(value);
    await axios.get('/api/setWebsiteLanguage', {
      headers: {
        [WEBSITE_LANGUAGE]: value.id
      }
    });
    await router.replace(router.route, router.asPath, { locale: value.id, shallow: true });
    router.reload();
  };

  // const selectSite = (value: { id: string; name: string; url?: string }) => {
  //   if (value.url) {
  //     window.open(value.url);
  //   }
  // };

  return (
    <div id={noticeHeaderId} className="w-full bg-[#232323]">
      <div className={classNames('w-full container mx-auto h-8.5', className)}>
        <div className={classNames('h-full flex items-center justify-between relative')}>
          {!isEmpty(noticeList) ? (
            <Swiper
              className="w-full h-full flex-1"
              direction="vertical"
              autoplay
              slidesPerView={1}
              loop
            >
              {noticeList.map((item, index) => (
                <SwiperSlide
                  className="flex items-center w-full h-full overflow-hidden"
                  key={index}
                >
                  <a
                    className={classNames(
                      'text-white text-xs cursor-pointer',
                      'line-clamp-1',
                      'pc:line-clamp-none'
                    )}
                    href={item.url}
                  >
                    {item.title}
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="w-full h-full flex-1"></div>
          )}
          <div className={classNames('items-center h-full', 'hidden', 'pc:flex')}>
            {sso.settled && showUser && (
              <>
                <div className={classNames('text-xs text-white cursor-pointer')}>
                  {user ? (
                    <div
                      className={classNames('flex w-25.5 justify-end')}
                      onClick={() => NextRouter.push(ROUTE_MAP.ACCOUNT)}
                    >
                      <p className="mr-1">{t('hello')},</p>
                      <p className={classNames('w-17.5 line-clamp-1 text-left flex-1 break-words')}>
                        {user.name}
                      </p>
                    </div>
                  ) : (
                    <div className={classNames('flex')}>
                      <WhiteOutlineIcon icon="user" className={classNames('w-4 h-4 mr-1')} />
                      <span onClick={() => globalAuth.login()}>{t('notice.login')}</span>
                    </div>
                  )}
                </div>
                <div className="h-3 border-l border-[rgba(255,255,255,0.3)] mx-4" />
              </>
            )}
            {/* 语言选择框 */}
            <div>
              <Select
                align={{ points: ['br'], offset: [30, 10] }}
                selectClassName="bg-transparent border-none text-white px-0 text-xs"
                selectItemClassName={classNames(
                  'h-11 w-35.5 justify-center border-black-6 bg-black-8 text-black'
                )}
                itemSelectedBgColor={true}
                value={language}
                options={LANGUAGE_OPTIONS}
                onChange={selectLanguage}
                solideArrow={true}
              />
            </div>
            {/* <div className="h-3 border-l border-[rgba(255,255,255,0.3)] mx-4" /> */}
            {/* 站点选择框 */}
            {/* <Select
              align={{ points: ['br'], offset: [30, 10] }}
              selectClassName="bg-transparent border-none text-white px-0 text-xs"
              selectItemClassName="h-11 w-35.5 justify-center border-black-6"
              itemSelectedBgColor={true}
              value={SiteList[0]}
              options={SiteList}
              onChange={selectSite}
              solideArrow={true}
            /> */}
          </div>
          {!inRN && (
            <>
              <div className="h-3 border-l border-[rgba(255,255,255,0.3)] mx-1 block pc:hidden" />
              <div className={classNames('flex pc:hidden')}>
                <Select
                  align={{ points: ['br'], offset: [30, 10] }}
                  selectClassName="bg-transparent border-none text-white px-0 text-xs"
                  selectItemClassName={classNames(
                    'h-11 w-35.5 justify-center border-black-6 bg-black-8 text-black'
                  )}
                  itemSelectedBgColor={true}
                  value={language}
                  options={LANGUAGE_OPTIONS}
                  onChange={selectLanguage}
                  solideArrow={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
