import { classNames as classnames } from '@common/application/utils/classnames';

export const IconArrow = (props: { color?: string; className?: string }) => {
  return (
    <span className={classnames('w-6 h-6 inline-flex', props.className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};
