import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { ICategory } from '@common/types/category';
import Link from 'next/link';
import Router from 'next/router';

import { Card } from '@/components/common/goods-category/card';

interface Data {
  key: string;
  firstData: ICategory;
  data: ICategory;
  className?: string;
  hasBrand?: boolean;
}
export const SecondCategory = (secondCategory: Data) => {
  const { data, firstData, className, hasBrand } = secondCategory;
  const titleClick = () => {
    Router.push(
      createPath(ROUTE_MAP.CATEGORY_LIST, {
        category: [firstData.name, data.name],
        id: data.id
      })
    );
  };
  return (
    <Card title={data.name} ontTitleClick={titleClick} className={classNames('pt-4', className)}>
      {Boolean(data.childTree?.length) && (
        <ul
          className={classNames('grid grid-cols-10 gap-3 my-3 w-full', {
            'grid-cols-8': hasBrand
          })}
        >
          {data.childTree.map((item) => (
            <Link
              key={item.id}
              href={createPath(ROUTE_MAP.CATEGORY_LIST, {
                category: [firstData.name, data.name, item.name],
                id: item.id
              })}
              className="col-span-2"
            >
              <li className="col-span-2 flex items-center cursor-pointer group">
                <DImage
                  className="relative flex-shrink-0 mr-3 w-13.5 h-13.5 rounded-1 overflow-hidden"
                  src={item.pcPicturePath}
                  fill
                />
                <span className="flex-1 text-black-3 text-sm line-clamp-2 group-hover:text-primary">
                  {item.name}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      )}
    </Card>
  );
};
