import { IS_CLIENT } from '@common/constants/common';
import axios from 'axios';
import { format } from 'date-fns';
import { get } from 'lodash-es';
function stringify(value) {
    return IS_CLIENT ? value : `${JSON.stringify(value)} \n`;
}
/**
 * 输出request 参数
 * @param request
 */
export const logRequest = (request) => {
    if (process.env.NODE_ENV !== 'production' && IS_CLIENT) {
        console.info(`\n[Network Request]：${request.url} ${format(Date.now(), 'yyyy-MM-dd HH:mm:ss')}\n`, stringify(request.headers), stringify(request.data), stringify(request.params));
    }
};
/**
 * 输出response参数
 * @param response
 * @param timing
 */
export const logResponse = (response, timing) => {
    if (process.env.NODE_ENV !== 'production' && IS_CLIENT) {
        console.log('\n');
        console.log(`[Network Response]：${response.config.url} ${Math.floor(timing)}ms \n`, stringify(response.data));
    }
};
/**
 * 异常输出
 * @param error
 */
export const logError = (error) => {
    if (process.env.NODE_ENV !== 'production') {
        console.log('\n');
        if (axios.isCancel(error)) {
            console.log(`[Network Error]：${error.message}\n`);
        }
        else {
            console.log(`[Network Error]：${error.config.url}\n ${get(error, 'response.status')} `, stringify(get(error, 'data', get(error, 'response.data', { errMsg: '未知错误' }))));
        }
    }
};
