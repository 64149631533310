import { atom } from 'recoil';
import { RECOIL_KEY } from './key';
/**
 * 全局默认断点
 *
 * 服务端/客户端 均可用
 */
export const globalDefaultBreakpoint = atom({
    key: RECOIL_KEY.GLOBAL_DEFAULT_BREAKPOINT,
    default: 'mobile'
});
export const globalIsInApp = atom({
    key: RECOIL_KEY.GLOBAL_IS_IN_APP,
    default: false
});
export const globalIsRobot = atom({
    key: RECOIL_KEY.GLOBAL_IS_ROBOT,
    default: false
});
export const globalConfig = atom({
    key: RECOIL_KEY.GLOBAL_CONFIG,
    default: {}
});
