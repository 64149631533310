import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { pageHomeCategory } from '@common/application/store/category';
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { AspectRatioView } from '@common/components/common/aspect-ratio-view';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { ICategory } from '@common/types/category';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';

import { IMG_ICON_LIVING_LOGO, IMG_NO_DATA } from '@/resources/images';

import { Card } from './card';
import { SecondCategory } from './second-category';
import { SecondCategoryMobile } from './second-category-mobile';

interface Data {
  data: ICategory[];
  firstData: ICategory;
}

/**
 * 品牌brand展示
 */
const BrandCard = () => {
  const { recommendBrandList } = useRecoilValue(pageHomeCategory);
  const { t } = useTranslation(['common']);

  return (
    <Card
      title=""
      className={classNames('pt-6 pb-5.5', 'px-3', 'pc:px-4')}
      titleClassName={classNames('border-none !py-0')}
    >
      <div className={classNames('grid grid-flow-row grid-cols-12')}>
        <div
          className={classNames(
            'inline-block border-t border-black-5 self-center',
            'col-start-2 col-end-5',
            'pc:col-span-3'
          )}
        />
        <h3
          className={classNames(
            'text-sm font-medium justify-self-center',
            'col-span-4',
            'pc:col-span-6'
          )}
        >
          {t('hotBrand')}
        </h3>
        <div
          className={classNames(
            'inline-block border-t border-black-5 self-center',
            'col-start-9 col-end-12',
            'pc:col-span-3'
          )}
        ></div>
      </div>
      <div className={classNames('grid gap-3 mt-5', 'grid-cols-4', 'pc:grid-cols-2')}>
        {recommendBrandList.map((item) => (
          <AspectRatioView key={item.objId} className="col-span-1">
            <a
              className="w-full h-full"
              href={createPath(
                ROUTE_MAP.BRANCH,
                { name: item.name, brandId: item.objId },
                { withLocale: true }
              )}
              target="_black"
            >
              <DImage
                fill
                alt={item.name}
                className={classNames('w-full h-full border border-black-6 rounded cursor-pointer')}
                src={item.picUrl || IMG_ICON_LIVING_LOGO}
              />
            </a>
          </AspectRatioView>
        ))}
      </div>
    </Card>
  );
};

export const FirstCategory = (firstCategory: Data) => {
  const { recommendBrandList } = useRecoilValue(pageHomeCategory);
  const { t } = useTranslation(['common']);
  const screens = useBreakpoint();

  const { data, firstData } = firstCategory;
  const hasBrand = !isEmpty(recommendBrandList);

  return screens.pc ? (
    <div className={classNames('w-full h-full bg-white grid grid-cols-10 gap-3')}>
      <div
        className={classNames('col-span-8 px-3 py-4 h-full overflow-y-auto', {
          'col-span-10': !hasBrand
        })}
      >
        {!isEmpty(data) ? (
          data.map((item) => (
            <SecondCategory key={item.id} data={item} firstData={firstData} hasBrand={hasBrand} />
          ))
        ) : (
          <div
            className={classNames('border-r col-span-8 h-80 border-black-7', {
              'col-span-10': !hasBrand
            })}
          >
            <div className="flex flex-col justify-center items-center h-full">
              <DImage src={IMG_NO_DATA} className="w-75 h-42.5" />
              <p className="text-sm text-black-3">{t('noData')}</p>
            </div>
          </div>
        )}
      </div>
      {hasBrand && (
        <div className="col-span-2 overflow-y-auto">
          <BrandCard />
        </div>
      )}
    </div>
  ) : (
    <>
      <div className="px-3">
        {data.map((item) => (
          <SecondCategoryMobile key={item.id} data={item} firstData={firstData} />
        ))}
      </div>
      <BrandCard />
    </>
  );
};
