import { useElementBounding } from '@common/application/hooks/use-element-bounding';
import { useToggle } from 'ahooks';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { GoodsCategory } from '@/components/common/goods-category';
import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

export const CategoryMobile = () => {
  const [open, openActions] = useToggle();

  const triggerEl = useRef<HTMLDivElement | null>(null);

  const { bottom: categoryTop, isReady } = useElementBounding(triggerEl);

  const router = useRouter();

  useEffect(() => {
    openActions.set(false);
  }, [router.query.path]);

  return (
    <div className="w-full h-full text-base" data-testid={'test-category-mobile'}>
      <div className="h-full flex items-center" ref={triggerEl} onClick={openActions.toggle}>
        <BlackLineIcon className="w-6 h-6" icon="category" />
      </div>
      {isReady && (
        <GoodsCategory open={open} top={categoryTop} onHide={() => openActions.set(false)} />
      )}
    </div>
  );
};
