import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const BlackLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    user: 1,
    notice: 2,
    cart: 3,
    search: 4,
    'top-arrow': 5,
    'bottom-arrow': 6,
    'left-arrow': 7,
    'right-arrow': 8,
    delete: 9,
    eye: 10,
    'eye-close': 11,
    bag: 12,
    home: 13,
    edit: 14,
    x: 15,
    category: 16,
    calendar: 17,
    car: 18,
    store: 19,
    box: 20,
    share: 21,
    reset: 22,
    'category-close': 23,
    like: 24,
    plus: 25,
    minus: 26
  }
});
