import { createContext } from 'react';
/**
 * 表单上下文
 */
export const FormContext = createContext({
    isDefault: true,
    instance: {}
});
/**
 * 表单单元项上下文
 */
export const FormItemContext = createContext({
    isDefault: true,
    control: {},
    meta: {},
    status: ''
});
