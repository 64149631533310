import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { AspectRatioView } from '@common/components/common/aspect-ratio-view';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { ICategory } from '@common/types/category';
import Link from 'next/link';
import Router from 'next/router';

import { Card } from '@/components/common/goods-category/card';

interface Data {
  key: string;
  firstData: ICategory;
  data: ICategory;
  className?: string;
}
export const SecondCategoryMobile = (secondCategory: Data) => {
  const { data, firstData, className } = secondCategory;
  const titleClick = () => {
    Router.push(
      createPath(ROUTE_MAP.CATEGORY_LIST, {
        category: [firstData.name, data.name],
        id: data.id
      })
    );
  };

  return (
    <Card
      title={data.name}
      ontTitleClick={titleClick}
      className={classNames('text-sm font-medium', className)}
      titleClassName="border-black-7"
    >
      {Boolean(data.childTree?.length) && (
        <ul className="grid grid-cols-3 gap-6 my-2">
          {data.childTree.map((item, index) => (
            <Link
              key={item.id}
              href={createPath(ROUTE_MAP.CATEGORY_LIST, {
                category: [firstData.name, data.name, item.name],
                id: item.id
              })}
              data-testid={`test-mobile-second-category-${index}`}
            >
              <li>
                <AspectRatioView className="mb-2 w-full">
                  <DImage
                    className="relative w-full h-full rounded-1 overflow-hidden"
                    src={item.picturePath}
                    fill
                  />
                </AspectRatioView>
                <div className="text-center text-xs line-clamp-2 text-black-3">{item.name}</div>
              </li>
            </Link>
          ))}
        </ul>
      )}
    </Card>
  );
};
