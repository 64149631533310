import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const GreyLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    car: 1,
    warning: 2,
    tel: 3,
    location: 4,
    'img-plus': 5,
    search: 6,
    'right-arrow': 7,
    time: 8,
    add: 9,
    'top-triangle': 10,
    broadcast: 11
  }
});
