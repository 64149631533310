import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClientValue } from '@common/application/hooks/use-client-value';
import { ANDROID_STORE_URL, IOS_STORE_URL } from '@common/constants/common';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { DImage } from './image';
import { Sticky } from './sticky';
export const downLoadNoticeId = 'download-notice';
const DownLoadNotice = ({ logoImage }) => {
    const router = useRouter();
    const isAndroid = useClientValue(() => {
        const u = navigator.userAgent;
        return u.includes('Android') || u.includes('Adr');
    });
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation(['common']);
    useEffect(() => {
        const isFromDownload = sessionStorage.getItem('isFromDownload');
        if (isFromDownload !== 'true') {
            const downloadParam = router.query.download;
            if (downloadParam === 'true') {
                sessionStorage.setItem('isFromDownload', 'true');
            }
            else {
                sessionStorage.removeItem('isFromDownload');
            }
        }
        else {
            sessionStorage.setItem('isFromDownload', isFromDownload);
        }
    }, [router.query]);
    useEffect(() => {
        // 从 sessionStorage 检查是否需要显示组件
        const isFromDownload = sessionStorage.getItem('isFromDownload');
        if (isFromDownload !== 'true') {
            setIsVisible(false);
        }
    }, []);
    const handleClose = () => {
        setIsVisible(false);
        sessionStorage.setItem('isFromDownload', 'false');
    };
    if (!isVisible) {
        return null;
    }
    const handleDownload = () => {
        if (isAndroid) {
            window.location.href = ANDROID_STORE_URL;
        }
        else {
            window.location.href = IOS_STORE_URL;
        }
    };
    return (_jsx(Sticky, Object.assign({ className: "flex-shrink-0 w-full z-10", id: "page-header", wait: 0 }, { children: _jsxs("div", Object.assign({ id: downLoadNoticeId, className: "w-full min-h-12 bg-black flex" }, { children: [_jsxs("div", Object.assign({ className: "py-1.5 bg-black flex items-center flex-1" }, { children: [_jsxs("div", Object.assign({ className: "relative w-4 h-4 border-2 border-white rounded-full flex items-center justify-center ml-3 mr-3 shrink-0", onClick: handleClose }, { children: [_jsx("div", { className: "absolute w-2 h-0.5 bg-white transform rotate-45" }), _jsx("div", { className: "absolute w-2 h-0.5 bg-white transform -rotate-45" })] })), _jsxs("div", Object.assign({ className: "flex justify-center items-center" }, { children: [_jsx("div", Object.assign({ className: "w-8.5 h-8.5 rounded-[6px] bg-white overflow-hidden shrink-0" }, { children: _jsx(DImage, { src: logoImage, alt: "logo", className: "w-full h-full" }) })), _jsx("div", Object.assign({ className: "mx-4 flex-grow" }, { children: _jsx("div", Object.assign({ className: "text-[#fff] text-[13px] text-left leading-[17px]" }, { children: t('downloadNotice.notice1') })) }))] }))] })), _jsx("div", Object.assign({ className: "bg-[#DC3030] w-24 flex justify-center items-center text-page-bg text-[14px]", onClick: handleDownload }, { children: t('downloadNotice.goToMall') }))] })) })));
};
export default DownLoadNotice;
