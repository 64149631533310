var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 朱璐
 * @Date: 2022-10-31 16:44:32
 * @Description: 结算页api
 */
import request from '@common/application/utils/request';
import { DELIVERY_TYPE } from '@common/constants/checkout';
import { API_MAP } from './api-map';
export class CheckoutApi {
    /** 查询结算信息 */
    static getCheckoutInfo(params, config = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CHECKOUT_INFO, params, config).then((res) => {
                // 接口错误
                if (res.errorCode) {
                    return Object.assign(Object.assign({}, res), { errorMsg: JSON.parse(res.errorMsg) });
                }
                res.checkoutItemList = res.checkoutItemList.map((item) => {
                    var _a, _b, _c, _d;
                    const source = (item.deliveryType === DELIVERY_TYPE.HOME_DELIVERY
                        ? item.deliveryTimes
                        : item.pickupTimes) || [];
                    const dateSource = [];
                    const timeSource = [
                        {
                            id: `${(_a = source === null || source === void 0 ? void 0 : source[0]) === null || _a === void 0 ? void 0 : _a.startTime}-${(_b = source === null || source === void 0 ? void 0 : source[0]) === null || _b === void 0 ? void 0 : _b.endTime}`,
                            name: `${(_c = source === null || source === void 0 ? void 0 : source[0]) === null || _c === void 0 ? void 0 : _c.startTime}-${(_d = source === null || source === void 0 ? void 0 : source[0]) === null || _d === void 0 ? void 0 : _d.endTime}`
                        }
                    ];
                    source.forEach((d) => {
                        dateSource.push({ id: d.date, name: d.date });
                    });
                    return Object.assign(Object.assign({}, item), { checkoutMerchant: Object.assign(Object.assign({}, item.checkoutMerchant), { dateSource,
                            timeSource, selectDate: (dateSource === null || dateSource === void 0 ? void 0 : dateSource[0]) || null, selectTime: (timeSource === null || timeSource === void 0 ? void 0 : timeSource[0]) || null }) });
                });
                // 处理默认选择自提门店--现提货点改为可选择
                // res.pickOnlineStoreId = get(res, 'pickOnlineStores.[0].id') || '';
                return res;
            });
        });
    }
    static createOrder(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CREATE_ORDER, params).then((res) => {
                // 接口错误
                if (res.errorCode) {
                    return Object.assign(Object.assign({}, res), { errorMsg: JSON.parse(res.errorMsg) });
                }
                return res;
            });
        });
    }
    /**
     * 用户积分详情
     */
    static getPointDetail(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.CHECKOUT_POINT_DETAIL, {}, config);
        });
    }
    /**
     * 积分核销
     */
    static pointWriteOff(data, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CHECKOUT_POINT_WRITE_OFF, data, config);
        });
    }
    /**
     * 获取地址列表
     */
    static getAddressList(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.CHECKOUT_ADDRESS_LIST, params, config);
        });
    }
    /**
     * 线下转账上传凭证
     */
    static uploadTransferVoucher(data, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.UPLOAD_PAYMENT, data, config);
        });
    }
    /**
     * 支付凭证回显
     */
    static getPaymentVoucher(parentOrderNumber, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.PAYMENT_VOUCHER_IMG, { parentOrderNumber }, config);
        });
    }
    /**
     * 查询支付状态
     */
    static getPayStatus(parentOrderNumber, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.PAY_STATUS, { parentOrderNumber }, config);
        });
    }
}
