var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { isFunction } from 'lodash-es';
import { Field as RcField } from 'rc-field-form';
import { useMemo } from 'react';
import { FormItemContext } from './context';
import { useForm, useFormItemProps } from './hooks';
const InternalFormItem = (props) => {
    const form = useForm();
    const { className, errorClassName, labelClassName, labelErrorClassName, inputClassName, inputErrorClassName, isRequired, label, colon, labelHtmlFor, control, meta, children } = useFormItemProps(props);
    const status = useMemo(() => {
        if (!meta.validating) {
            if (meta.errors.length)
                return 'error';
            if (meta.warnings.length)
                return 'warning';
        }
        return '';
    }, [meta]);
    const formItemContextValue = useMemo(() => ({
        isDefault: false,
        control,
        meta,
        status: status,
        disabled: form === null || form === void 0 ? void 0 : form.disabled
    }), [control, meta, status, form === null || form === void 0 ? void 0 : form.disabled]);
    const isError = status === 'error';
    const FormLabelComponent = (labelHtmlFor ? 'div' : 'label');
    const FormItemComponent = (labelHtmlFor ? 'label' : 'div');
    return (_jsx(FormItemContext.Provider, Object.assign({ value: formItemContextValue }, { children: _jsxs(FormItemComponent, Object.assign({ className: classNames(className, {
                'text-red': isError,
                [errorClassName]: isError
            }) }, { children: [label && (_jsxs(FormLabelComponent, Object.assign({ className: classNames(labelClassName, {
                        'text-red': isError,
                        [labelErrorClassName]: isError
                    }) }, { children: [_jsx("span", Object.assign({ className: "text-red" }, { children: isRequired ? '*' : '' })), label, colon ? '：' : ''] }))), _jsxs("div", Object.assign({ className: classNames(inputClassName, {
                        'text-red': isError,
                        [inputErrorClassName]: isError
                    }) }, { children: [children, status === 'error' && (_jsx("div", Object.assign({ onClick: (e) => e.stopPropagation() }, { children: meta.errors.map((error) => (_jsx("p", { children: error }, error))) })))] }))] })) })));
};
export const FormItem = (props) => {
    const { className, labelClassName, inputClassName, errorClassName, labelErrorClassName, inputErrorClassName, isRequired, label, colon, rules, labelHtmlFor, children } = props, rcFieldProps = __rest(props, ["className", "labelClassName", "inputClassName", "errorClassName", "labelErrorClassName", "inputErrorClassName", "isRequired", "label", "colon", "rules", "labelHtmlFor", "children"]);
    const mergedIsRequired = useMemo(() => {
        if (isRequired)
            return true;
        if (!Array.isArray(rules))
            return false;
        return rules.some((rule) => {
            return !isFunction(rule) ? rule.required : false;
        });
    }, [isRequired, rules]);
    return (_jsx(RcField, Object.assign({ rules: rules }, rcFieldProps, { children: (control, meta) => (_jsx(InternalFormItem, Object.assign({ className: className, labelClassName: labelClassName, inputClassName: inputClassName, errorClassName: errorClassName, labelErrorClassName: labelErrorClassName, inputErrorClassName: inputErrorClassName, isRequired: mergedIsRequired, label: label, colon: colon, labelHtmlFor: labelHtmlFor, control: control, meta: meta }, { children: children }))) })));
};
FormItem.displayName = 'FormItem';
FormItem.defaultProps = {};
