import { useRouter } from 'next/router';

export function useLink() {
  const router = useRouter();

  return (url: string) => {
    let target: URL;
    try {
      target = new URL(url);
      if (target.origin === window.location.origin) {
        router.push(target.pathname);
      } else {
        window.location.href = url;
      }
    } finally {
      window.location.href = url;
    }
  };
}
