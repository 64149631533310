import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-02 17:05:25
 * @Description: 基础筛选组件
 */
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { productListParams } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { Button } from '@common/components/common/button';
import { Modal } from '@common/components/common/modal';
import { Radio } from '@common/components/common/radio';
import { IconArrow } from '@common/components/icon/arrow';
import { IconCheckboxChecked } from '@common/components/icon/checkbox-checked';
import { IconCheckboxUnCheck } from '@common/components/icon/checkbox-unchecked';
import { useTranslation } from 'next-i18next';
import React, { useContext, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { RenderTypeContext } from '../context/render-type';
const ViewMore = 8;
export const BaseFilter = ({ options, title, value, expanded, onChange, className, type, renderType = 'checkbox' }) => {
    const [_expanded, setExpanded] = useState(expanded);
    const { t } = useTranslation(['product-list']);
    const [showMore, setShowMore] = useState(false);
    const [showBrandModal, setShowBrandModal] = useState(false);
    const brandList = useRef([]);
    const { mobile } = useBreakpoint();
    const [filter, setFilter] = useRecoilState(productListParams);
    const { renderType: plpType } = useContext(RenderTypeContext);
    const renderRadio = (item, type) => {
        const changeVal = (val, isSearch) => {
            if (val === value[0]) {
                onChange([], isSearch);
            }
            else {
                onChange([val], isSearch);
            }
        };
        return (_jsx("div", Object.assign({ "data-testid": "filter-item", className: classNames('flex items-center', { 'py-2': type === 'list' }), onClick: (e) => {
                e.preventDefault();
                if (type === 'list' && !mobile) {
                    changeVal(item.id, true);
                }
                else {
                    changeVal(item.id, false);
                }
            } }, { children: _jsxs(Radio, Object.assign({ className: "mr-6", checked: (value === null || value === void 0 ? void 0 : value[0]) === item.id, iconClassName: "w-4 h-4 text-gray-1", iconCheckedClassName: "text-primary", contentClassName: "flex flex-1" }, { children: [_jsx("span", Object.assign({ className: classNames('break-all text-black-1 line-clamp-1 ml-2', 'text-1xs', 'pc:text-sm') }, { children: item.name })), _jsxs("span", Object.assign({ className: classNames('text-black-1', 'text-1xs', 'pc:text-sm') }, { children: ["\uFF08", item === null || item === void 0 ? void 0 : item.count, "\uFF09"] }))] })) }), item.id));
    };
    const renderCheckbox = (item, showType) => {
        return (_jsxs("div", Object.assign({ "data-testid": "brand-filter-item", className: classNames('flex items-center', {
                'text-primary': value.indexOf(item.id) > -1,
                'py-2': showType === 'list'
            }), onClick: () => {
                const _value = new Set(value);
                if (_value.has(item.id)) {
                    _value.delete(item.id);
                }
                else {
                    _value.add(item.id);
                }
                if (showType === 'list' && !mobile) {
                    onChange([..._value], true);
                }
                else {
                    onChange([..._value], false);
                }
            } }, { children: [value.indexOf(item.id) > -1 ? (_jsx(IconCheckboxChecked, { className: "text-primary flex-shrink-0 w-4 h-4" })) : (_jsx(IconCheckboxUnCheck, { className: "flex-shrink-0 w-4 h-4" })), _jsx("span", Object.assign({ className: classNames('break-all text-black-1 line-clamp-1 ml-2', 'text-1xs', 'pc:text-sm') }, { children: item.name })), _jsxs("span", Object.assign({ className: "text-sm text-black-1" }, { children: ["\uFF08", item === null || item === void 0 ? void 0 : item.count, "\uFF09"] }))] }), item.id));
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames('w-full text-lg', {
                    'pb-4': _expanded,
                    'border-b border-black-7': !_expanded
                }, className), "data-testid": "filter-item-wrapper" }, { children: [_jsxs("div", Object.assign({ "data-testid": "filter-item-expand", className: classNames('w-full h-12 flex items-center justify-between cursor-pointer', 'border-none', 'pc:border-b pc:border-black-7'), onClick: () => setExpanded((prev) => !prev) }, { children: [_jsx("span", Object.assign({ className: "text-base text-black-1 font-medium line-clamp-1" }, { children: title })), _jsx(IconArrow, { className: classNames('w-6 h-6 flex-shrink-0', { 'rotate-180': !_expanded }, 'text-[#1A1A1A]') })] })), _jsxs("div", Object.assign({ className: classNames('grid gap-y-2', { hidden: !_expanded }) }, { children: [options.map((item, index) => {
                                if (mobile) {
                                    if (index > 7 && !showMore)
                                        return null;
                                }
                                else if (index > 7 && !showMore)
                                    return null;
                                return renderType === 'checkbox'
                                    ? renderCheckbox(item, 'list')
                                    : renderRadio(item, 'list');
                            }), options.length > ViewMore && !showMore && (_jsx(Button, Object.assign({ "data-testid": "filter-item-show-more", color: "black-8", className: classNames('border-0', {
                                    'text-black-4': plpType === 'ahaa',
                                    'text-[#999999]': plpType === 'living'
                                }), onClick: () => {
                                    if (mobile) {
                                        setShowMore(true);
                                    }
                                    else if (type === 'brand') {
                                        brandList.current = filter.brands || [];
                                        setShowBrandModal(true);
                                    }
                                    else {
                                        setShowMore(true);
                                    }
                                } }, { children: t('filterMore') })))] }))] })), _jsx(Modal, Object.assign({ className: "max-w-140 h-113.25 rounded-lg bg-white", headerClassName: "flex items-center justify-center pc:py-6", bodyClassName: classNames('grid grid-cols-2 gap-4 px-5 pb-6'), footerClassName: "shadow-nav-sm", title: title, open: showBrandModal, maskCancelable: true, onCancel: () => {
                    if (filter.brands !== brandList.current) {
                        setFilter((org) => (Object.assign(Object.assign({}, org), { brands: brandList.current })));
                    }
                    setShowBrandModal(false);
                }, getContainer: () => document.body, onConfirm: () => {
                    onChange(value, true);
                    setShowBrandModal(false);
                }, destroyCloseIcon: false, confirmProps: { className: 'bg-primary border-none' }, cancelProps: { className: 'bg-black-6 border-none' } }, { children: options.map((item) => {
                    return renderType === 'checkbox'
                        ? renderCheckbox(item, 'model')
                        : renderRadio(item, 'model');
                }) }))] }));
};
BaseFilter.defaultProps = {
    expanded: false,
    value: []
};
