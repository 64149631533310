/*
 * @Author: 朱璐
 * @Date: 2022-10-31 16:32:36
 * @Description: 全局recoilValue map
 */
import { isEqual, noop } from 'lodash-es';
import { createElement, useRef } from 'react';
import { RecoilRoot } from 'recoil';
import { applyAfterSales } from './after-sales';
import { cartDetail } from './cart';
import { pageHomeCategory } from './category';
import { checkoutState, pickupLimitMerchantsState, pointDetailState } from './checkout';
import { pageTitleParams } from './common';
import { evaluationOrderDetail } from './evaluation';
import { globalConfig, globalDefaultBreakpoint, globalIsInApp, globalIsRobot } from './global';
import { isRNWebView } from './is-rn-webview';
import { orderDetail } from './order-detail';
import { breadcrumbState as productDetailBreadcrumbState, merchantInfoState, productDetailState, productSeoConfigState, recommendProductsState as productDetailRecommendProductsState, reviewsTotalState } from './product-detail';
import { categoryBreadcrumb, prodcutListInitPrice, prodcutListPageTypeState, productList, productListBanners, productListBaseFilter, productListCategory, productListParams, productListSourceParams } from './product-list';
import { consigneeAddressList } from './user';
export const globalRecoilValues = {
    [productList.key]: productList,
    [productListBaseFilter.key]: productListBaseFilter,
    [productListCategory.key]: productListCategory,
    [productListParams.key]: productListParams,
    [checkoutState.key]: checkoutState,
    [productDetailState.key]: productDetailState,
    [productDetailBreadcrumbState.key]: productDetailBreadcrumbState,
    [productDetailRecommendProductsState.key]: productDetailRecommendProductsState,
    [consigneeAddressList.key]: consigneeAddressList,
    [categoryBreadcrumb.key]: categoryBreadcrumb,
    [cartDetail.key]: cartDetail,
    [pageTitleParams.key]: pageTitleParams,
    [orderDetail.key]: orderDetail,
    [applyAfterSales.key]: applyAfterSales,
    [globalDefaultBreakpoint.key]: globalDefaultBreakpoint,
    [pointDetailState.key]: pointDetailState,
    [evaluationOrderDetail.key]: evaluationOrderDetail,
    [pageHomeCategory.key]: pageHomeCategory,
    [prodcutListInitPrice.key]: prodcutListInitPrice,
    [productListBanners.key]: productListBanners,
    [merchantInfoState.key]: merchantInfoState,
    [isRNWebView.key]: isRNWebView,
    [productListSourceParams.key]: productListSourceParams,
    [productSeoConfigState.key]: productSeoConfigState,
    [reviewsTotalState.key]: reviewsTotalState,
    [pickupLimitMerchantsState.key]: pickupLimitMerchantsState,
    [globalIsRobot.key]: globalIsRobot,
    [prodcutListPageTypeState.key]: prodcutListPageTypeState,
    [globalIsInApp.key]: globalIsInApp,
    [globalConfig.key]: globalConfig
};
export const InitRecoilRoot = (props) => {
    const { state: recoilState = {}, children } = props;
    const setRef = useRef(noop);
    const recoilStateRef = useRef();
    function Updater() {
        if (!isEqual(recoilStateRef.current, recoilState)) {
            recoilStateRef.current = recoilState;
            for (const [key, state] of Object.entries(recoilState)) {
                setRef.current(globalRecoilValues[key], state);
            }
        }
        return null;
    }
    return createElement(RecoilRoot, {
        initializeState({ set }) {
            setRef.current = set;
            // 首次初始化
            Updater();
        }
    }, 
    // 路由切换后重新初始化
    createElement(Updater), children);
};
