import { CommonApi } from '@common/api/common';
import { currentStore } from '@common/application/store/common';
import { useAddSearchHistory } from '@common/application/store/search';
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import { isEmpty } from 'lodash-es';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  onListChange?: (list: string[]) => void;
  clickItemHot?(value: string): void;
}

export const SearchHot = ({ onListChange, clickItemHot }: Props) => {
  const { t } = useTranslation('common');
  const [hotList, setHotList] = useState<string[]>([]);
  const handleAddSearchHistory = useAddSearchHistory();
  const store = useRecoilValue(currentStore);

  const setList = (list: string[]) => {
    onListChange?.(list);
    setHotList(list);
  };

  const handleSearch = (value: string) => {
    if (clickItemHot) clickItemHot(value);
    handleAddSearchHistory(value);
    Router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: value }));
  };

  useEffect(() => {
    CommonApi.getHotWords().then(setList);
  }, [store]);

  return isEmpty(hotList) ? (
    <></>
  ) : (
    <div className="w-full pc:p-4">
      <div
        className={classNames(
          'text-base font-medium text-black-1',
          'pc:text-sm pc:font-normal pc:text-black-3'
        )}
      >
        {t('searchExtend.hotSearch')}
      </div>
      <div
        className={classNames('flex flex-wrap -mr-4 overflow-hidden ', 'max-h-22', 'pc:max-h-16')}
      >
        {hotList?.map((item, index) => (
          <div
            key={index}
            className={classNames(
              'mt-3 mr-3 text-black-1 cursor-pointer',
              'px-3 py-1.5 rounded-full bg-black-8 text-xs',
              'pc:p-0 pc:rounded-none pc:bg-transparent text-sm'
            )}
            onClick={() => handleSearch(item)}
          >
            <span className="line-clamp-1">{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
