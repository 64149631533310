import { createElement } from 'react';
import { classNames } from './classnames';
/**
 * 创建 icon 组件
 *
 * @param svg svg 元素
 */
export function createIconComponent(svg) {
    const IconComponent = (props) => createElement('i', Object.assign(Object.assign({}, props), { className: classNames('w-6 h-6 inline-block', props.className) }), svg);
    return IconComponent;
}
