import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { useMemoizedFn, useUpdateEffect } from 'ahooks';
import { isNil, noop } from 'lodash-es';
import React, { useMemo, useRef, useState } from 'react';
import { RadioGroupContext } from './context';
export const RadioGroup = (props) => {
    const { className, checkedClassName = '', itemClassName, itemCheckedClassName, iconClassName, iconCheckedClassName, contentClassName, contentCheckedClassName, value, disabled, name, children, onChange = noop } = props;
    const [defaultValue, setDefaultValue] = useState(() => props.defaultValue);
    const itemsSet = useRef([]);
    const mergedValue = value !== null && value !== void 0 ? value : defaultValue;
    useUpdateEffect(() => setDefaultValue(props.defaultValue), [props.defaultValue]);
    function registerItemValue(itemValue, defaultChecked) {
        itemsSet.current.push(itemValue);
        if (defaultChecked)
            setDefaultValue(itemValue);
    }
    function destroyItemValue(itemValue) {
        const index = itemsSet.current.indexOf(itemValue);
        if (index !== -1)
            itemsSet.current.splice(index, 1);
    }
    const getItemValueChecked = useMemoizedFn((itemValue) => {
        return itemValue === mergedValue;
    });
    const contextValue = useMemo(() => {
        return {
            isDefault: false,
            className: itemClassName,
            checkedClassName: itemCheckedClassName,
            iconClassName,
            iconCheckedClassName,
            contentClassName,
            contentCheckedClassName,
            name,
            disabled,
            registerValue: registerItemValue,
            destroyValue: destroyItemValue,
            getChecked: getItemValueChecked,
            changeChecked: onChange
        };
    }, [
        itemClassName,
        itemCheckedClassName,
        iconClassName,
        iconCheckedClassName,
        contentClassName,
        contentCheckedClassName,
        disabled
    ]);
    return (_jsx(RadioGroupContext.Provider, Object.assign({ value: contextValue }, { children: _jsx("div", Object.assign({ className: classNames(className, {
                [checkedClassName]: !isNil(value)
            }) }, { children: children })) })));
};
RadioGroup.displayName = 'RadioGroup';
RadioGroup.defaultProps = {};
