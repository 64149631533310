var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Cookie } from '@common/application/utils/cookie';
import { MEMBER_STORE_URL, ONLINE_STORE_CODE_STORAGE_KEY, STORAGE_PREFIX } from '@common/constants/common';
import env, { cookieOptions } from '@common/constants/env';
import { parse } from 'cookie';
import { AES, enc } from 'crypto-ts';
import { get } from 'lodash-es';
export default function setCookieMiddleware(ctx) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const { req } = ctx;
        const cryptoKey = STORAGE_PREFIX;
        const cookies = parse(((_a = req === null || req === void 0 ? void 0 : req.headers) === null || _a === void 0 ? void 0 : _a.cookie) || '');
        const cookieVserion = get(cookies, `${STORAGE_PREFIX}cookieVserion`);
        if ((cookieVserion &&
            AES.decrypt(cookieVserion, cryptoKey).toString(enc.Utf8) !== env.APP_VERSION) ||
            !cookieVserion) {
            Object.keys(cookies).forEach((i) => {
                if (i.indexOf(STORAGE_PREFIX) > -1) {
                    Cookie.set(ctx, i.replace(STORAGE_PREFIX, ''), '', -1, cookieOptions);
                }
            });
            Cookie.set(ctx, 'cookieVserion', env.APP_VERSION, 365, cookieOptions);
        }
        // 如果是elite路径且cookie中不含有门店信息，将elite门店设置在cookie中
        if (((req === null || req === void 0 ? void 0 : req.url) || '').includes(`/${MEMBER_STORE_URL}`) &&
            get(cookies, `${STORAGE_PREFIX}${ONLINE_STORE_CODE_STORAGE_KEY}`) !== env.MEMBER_STORE_CODE) {
            Cookie.set(ctx, ONLINE_STORE_CODE_STORAGE_KEY, env.MEMBER_STORE_CODE, 365, cookieOptions);
        }
    });
}
