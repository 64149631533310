import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export default {
  ...publicRuntimeConfig,
  ABOUT_US: '6280041153748271104',
  CONCAT_US: '6286096301721387008',
  MEMBER_CAMPAIGN: '6280046067190857728',
  SERVICE_POLICY: '6280045276916875264',
  BRAND_CAMPAIGN: '6280042390698852352',
  TERMS_AND_CONDITIONS: '6280043112253358080',
  DELIVERY_POLICY: '6280043833807863808',
  FAQ: '6286093278064410624',
  INSTALLATION_SERVICE: '7162372090845200384'
} as AppSettings;

export const global: any = { accessToken: '' };
export const cookieOptions = 'Path=/; ';
