import type { MouseEventHandler } from 'react';
import { useRef } from 'react';

type mousePosition = {
  x: number;
  y: number;
};
/**
 * Represents the category state and events.
 */
interface CategoryStateAndEvents<T> {
  /**
   * A function to handle mouse enter event on the menu.
   */
  onMenuMouseEnter: () => void;
  /**
   * A function to handle mouse move event on the wrapper element.
   */
  onWrapMouseMove: MouseEventHandler<HTMLDivElement>;
  /**
   * A function to handle mouse enter event on a row.
   */
  onRowMouseEnter: (item: T) => void;
  /**
   * A function to handle mouse leave event on the menu.
   */
  onMenuMouseLeave: () => void;
}

export function useCategory<T>(props: {
  subRef: React.RefObject<HTMLElement>;
  active: (item: T) => void;
}): CategoryStateAndEvents<T> {
  const { subRef, active } = props;
  // 鼠标位置
  const mouseTrack = useRef<{ x: number; y: number }[]>([]);
  const timer = useRef<NodeJS.Timeout>();
  const mouseInSub = useRef(false);

  /** 容器内鼠标move事件 */
  const onWrapMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    mouseTrack.current.push({
      x: e.pageX,
      y: e.pageY
    });
    if (mouseTrack.current.length > 3) {
      mouseTrack.current.shift();
    }
  };

  const onRowMouseEnter = (item: T) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    const curMouse = mouseTrack.current[mouseTrack.current.length - 1]; // 鼠标当前坐标
    const prevMouse = mouseTrack.current[mouseTrack.current.length - 2]; // 鼠标上一次坐标

    if (!subRef?.current) {
      return active(item);
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const delay = needDelay(subRef?.current, curMouse, prevMouse);
    if (delay) {
      // 加入延迟器，解决斜方移动切换，只能折线移动的问题
      timer.current = setTimeout(() => {
        if (mouseInSub.current) {
          return;
        }
        active(item);
        timer.current = undefined;
      }, 200);
    } else {
      active(item);
    }
  };

  const onMenuMouseEnter = () => {
    mouseInSub.current = true;
  };
  const onMenuMouseLeave = () => {
    mouseInSub.current = false;
  };

  return {
    onMenuMouseEnter,
    onWrapMouseMove,
    onRowMouseEnter,
    onMenuMouseLeave
  };
}

// 向量是终点坐标减去起点坐标
function vector(a: mousePosition, b: mousePosition) {
  return {
    x: b.x - a.x,
    y: b.y - a.y
  };
}

// 向量的叉乘
function vectorPro(v1: mousePosition, v2: mousePosition): number {
  return v1.x * v2.y - v1.y * v2.x;
}

// 用位运算高效判断符号相同
function sameSign(a: number, b: number) {
  // eslint-disable-next-line no-bitwise
  return (a ^ b) >= 0;
}

// 判断点是否在三角形内
function isPointInTrangle(p: mousePosition, a: mousePosition, b: mousePosition, c: mousePosition) {
  const pa = vector(p, a);
  const pb = vector(p, b);
  const pc = vector(p, c);

  const t1 = vectorPro(pa, pb);
  const t2 = vectorPro(pb, pc);
  const t3 = vectorPro(pc, pa);

  return sameSign(t1, t2) && sameSign(t2, t3);
}

// 是否需要延迟
export function needDelay(ele: HTMLElement, curMouse: mousePosition, prevMouse: mousePosition) {
  if (!curMouse || !prevMouse) {
    return;
  }
  const offset = ele.getBoundingClientRect();

  // 左上点
  const topleft = {
    x: offset.left,
    y: offset.top
  };
  // 左下点
  const leftbottom = {
    x: offset.left,
    y: offset.top + offset.height
  };
  return isPointInTrangle(curMouse, prevMouse, topleft, leftbottom);
}
