import { CommonApi } from '@common/api/common';
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useElementBounding } from '@common/application/hooks/use-element-bounding';
import { DImage } from '@common/components/common/image';
import type { NoticeType } from '@common/types/common';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { InView } from 'react-intersection-observer';

import { useLink } from '@/application/hooks/use-link';

import { Category } from './category';
import { CategoryMobile } from './category-mobile';

export const Navigation: React.FC = () => {
  const [, setIndex] = useState(4);
  const openLink = useLink();

  const triggerEl = useRef<HTMLDivElement | null>(null);
  /**
   * 出现在视口的元素List
   */
  const [inViewList, setInViewList] = useState<boolean[]>([]);
  const [categoryList, setCategoryList] = useState<NoticeType[]>([]);

  const bounding = useElementBounding(triggerEl);
  const boundingRigth = bounding.right;

  const { mobile, pad } = useBreakpoint();

  useEffect(() => {
    async function getData() {
      const categoryMsgList = await CommonApi.getNoticeMsgList({
        type: 2
      });
      setCategoryList(categoryMsgList);
    }
    getData();
  }, []);

  useEffect(() => {
    if (!isEmpty(inViewList)) {
      const fliterArr = inViewList.filter((item) => item);
      setIndex(fliterArr.length);
    }
  }, []);
  return (
    <div className="w-full bg-white border-b border-[#e5e5e5]">
      <div
        className={classNames(
          'w-full container mx-auto grid grid-flow-col',
          'h-11 grid-cols-[24px_auto]',
          'pc:h-12 pc:grid-cols-[125px_auto]'
        )}
      >
        {mobile || pad ? <CategoryMobile /> : <Category />}

        <div
          ref={triggerEl}
          className={classNames(
            'grid grid-flow-col h-full overflow-y-hidden overflow-x-auto justify-self-start max-w-full navigation-scrollbar',
            'ml-4 gap-4',
            'pc:ml-10 pc:gap-10'
          )}
        >
          {!isEmpty(categoryList) &&
            categoryList
              // eslint-disable-next-line sonarjs/cognitive-complexity
              .map((item, i) => (
                // InView组件当目标元素出现在视口内会是inView变为true
                <InView
                  key={`${item?.title}_${item?.imageId}_${i}`}
                  onChange={(inView, entry) => {
                    const categoryLength = inViewList.filter((item) => item).length;
                    if (mobile) {
                      /**
                       * 页面首次加载时，根据boundingRigth判断渐变文字，
                       * 滚动时大于boundingRigth设置index渐变文字
                       */
                      if (entry && entry.intersectionRect.right >= boundingRigth) {
                        // target.id是根据list的index设置
                        setIndex(Number(entry.target.id));
                        // 元素往回滚动时，视口内最后一个文字渐变
                      } else if (
                        entry &&
                        entry.target.id === '1' &&
                        entry.isIntersecting &&
                        categoryLength
                      ) {
                        setIndex(Number(entry.target.id) + categoryLength - 1);
                      }
                    } else if (entry && entry.intersectionRect.right >= boundingRigth) {
                      setIndex(Number(entry.target.id));
                    } else if (entry.target.id === '1' && entry.isIntersecting && categoryLength) {
                      setIndex(Number(entry.target.id) + categoryLength - 1);
                    }
                    // 只在页面首次加载时，收集inViewList
                    if (inViewList && inViewList?.length < categoryList.length) {
                      setInViewList((value) => {
                        return value.concat(inView);
                      });
                    }
                  }}
                >
                  {
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    ({ inView, ref }) => {
                      return (
                        <div
                          id={(i + 1).toString()}
                          ref={ref}
                          className={classNames(
                            'inline-block place-self-center text-black-1 cursor-pointer font-semibold w-full',
                            'text-[13px]',
                            'pc:text-sm'
                            // 去掉模糊效果
                            // {
                            //   'bg-clip-text bg-gradient-to-l from-transparent to-black text-transparent':
                            //     inView && i + 1 >= index && i + 1 !== categoryList.length
                            // }
                          )}
                          onClick={() => {
                            if (item.url) openLink(item.url);
                          }}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {item.menuType === '1' ? (
                            <span>{item.title}</span>
                          ) : (
                            <DImage
                              src={item?.image?.path}
                              fill
                              className="h-7 pc:h-8 relative block !z-1"
                              imgClassName="object-contain !max-w-[initial] !relative !w-[initial]"
                            />
                          )}
                        </div>
                      );
                    }
                  }
                </InView>
              ))}
        </div>
      </div>
      <style jsx>
        {`
          .navigation-scrollbar {
            &::-webkit-scrollbar {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};
