import 'swiper/css';

import { CommonApi } from '@common/api/common';
import { useAddSearchHistory } from '@common/application/store/search';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import classNames from 'classnames';
import { get } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SearchHistory } from '@/components/common/header/search-history';
import { SearchHot } from '@/components/common/header/search-hot';
import { SearchPrediction } from '@/components/common/header/search-prediction';
import { GreyFillIcon } from '@/resources/sprites-images/grey-fill-icon';
import { GreyLineIcon } from '@/resources/sprites-images/grey-line-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

SwiperCore.use([Autoplay]);

export const Search = () => {
  const { t } = useTranslation(['common']);
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const router = useRouter();
  const [search, setSearch] = useState('');

  useEffect(() => {
    if ((get(router, 'query.keywords') || '') !== search) {
      setSearch(get(router, 'query.keywords') as string);
    }
  }, []);

  const [defaultKeywords, setDefaultKeywords] = useState<string[]>([]);
  const [defaultKeywordIndex, setDefaultKeywordIndex] = useState(0);
  const hasDefaultKeywords = Boolean(defaultKeywords.length);

  useEffect(() => {
    CommonApi.getDefaultKeywords().then(setDefaultKeywords);
  }, []);
  useEffect(() => {
    if (router.route === ROUTE_MAP.BRANCH) {
      setSearch(router.query.name as string);
    } else if (router.route === ROUTE_MAP.SEARCH) {
      setSearch(router.query.q as string);
    }
  }, [router.query]);

  const searchWords = search
    ? search.trim()
    : hasDefaultKeywords
    ? defaultKeywords[defaultKeywordIndex]
    : '';

  const handleAddSearchHistory = useAddSearchHistory();

  const handleSearch = () => {
    handleAddSearchHistory(searchWords);
    router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: searchWords }));
  };

  return (
    <div className={classNames('relative h-full', 'w-55.5 px-0', 'pc:w-full')}>
      <div
        className={classNames(
          'w-full flex justify-between items-center bg-[#F9F9F9]',
          'h-8 px-3 rounded-full',
          'pc:h-11 pc:pl-4 pc:pr-0 pc:rounded-none'
        )}
      >
        <div className="flex-1 relative h-full">
          <div className="relative w-full h-full flex items-center">
            <a
              className="absolute top-0 bottom-0 left-0 right-0 z-3 pc:hidden"
              href={ROUTE_MAP.SEARCH}
            ></a>
            <GreyLineIcon icon="search" className={classNames('w-6 h-6 block mr-1', 'pc:hidden')} />
            <input
              className="relative flex-1 z-2 outline-none border-none bg-transparent placeholder:text-sm placeholder:text-black-4 text-black-1 w-4/5 pc:w-full"
              placeholder={!hasDefaultKeywords ? t('header.searchPlaceholder') : ''}
              value={search || ''}
              onInput={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                setSearch(e.target.value);
              }}
              maxLength={100}
              onFocus={() => setSearchBoxVisible(true)}
              onBlur={() => setSearchBoxVisible(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            {search && (
              <div
                className="hidden pc:block"
                onClick={() => {
                  setSearch('');
                }}
              >
                <GreyFillIcon icon="close" className="w-5 h-5 mr-3 cursor-pointer" />
              </div>
            )}
          </div>
          {hasDefaultKeywords && !search && (
            <Swiper
              className={classNames('!absolute top-0 w-6/7 h-full', 'left-7', 'pc:left-1')}
              initialSlide={defaultKeywordIndex}
              direction="vertical"
              autoplay
              loop
              onRealIndexChange={(swiper) => setDefaultKeywordIndex(swiper.realIndex)}
            >
              {defaultKeywords.map((keyword, index) => (
                <SwiperSlide
                  key={`${keyword}_${index}`}
                  className="flex items-center w-full h-full overflow-hidden"
                >
                  <span className="line-clamp-1 h-5 text-sm text-black-3">{keyword}</span>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div
          className={classNames(
            'w-11 h-11 bg-black place-items-center cursor-pointer rounded-sm',
            'hidden',
            'pc:grid'
          )}
          onClick={handleSearch}
        >
          <WhiteLineIcon icon="search" className="w-6 h-6" />
        </div>
      </div>
      <div
        className={classNames(
          'hover:block absolute left-0 right-0 bg-white text-sm text-black-1 shadow-search-sm',
          {
            hidden: !searchBoxVisible
          }
        )}
      >
        {search ? (
          <SearchPrediction keyword={search} />
        ) : (
          <>
            <SearchHistory clickItemHistory={(value) => setSearch(value)} />
            <div className={classNames('h-0.25 w-full bg-black-6')}></div>
            <SearchHot clickItemHot={(value) => setSearch(value)} />
          </>
        )}
      </div>
    </div>
  );
};
